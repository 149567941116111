import React, { useRef } from 'react';
import UploadJD from '../../components/modal/upload-jd';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Pages } from '../../utils/constants';
import { Input, Card, Col, Collapse, Row, Typography, Divider } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Button from '../../components/button';
import Text from '../../components/text';
import Quotation1Img from '../../assets/images/quotation1.png';
import Quotation2Img from '../../assets/images/quotation2.png';
import AvatarImg from '../../assets/images/avatar.png';

function Home() {
  const uploadJDModal = useRef();

  const getFaqs = () => {
    const prefix = 'jd-gpt::home::text::faqs::';
    const keys = [
      'what-is-jobster',
      'non-english-resume',
      'your-resume-different',
      'generative-ai',
      'different-from-others',
      'single-page',
    ];

    return keys.map((key, index) => {
      return {
        key: index,
        label: (
          <Typography.Title level={5} className='m-0'>
            <Text id={prefix + key + '::question'} />
          </Typography.Title>
        ),
        children: (
          <Typography.Paragraph>
            <Text id={prefix + key + '::answer'} />
          </Typography.Paragraph>
        ),
      };
    });
  };

  const testimonials = [
    {
      person: 'Cathy',
      position: 'Actuarial Analyst',
      company: 'Major Insurance Company',
      review:
        'I got lots of interview opportunities after applying for the openings Jobster recommended. I received my dream offer after that.',
    },
    {
      person: 'Cathy',
      position: 'Actuarial Analyst',
      company: 'Major Insurance Company',
      review:
        'I got lots of interview opportunities after applying for the openings Jobster recommended. I received my dream offer after that.',
    },
    {
      person: 'Cathy',
      position: 'Actuarial Analyst',
      company: 'Major Insurance Company',
      review:
        'I got lots of interview opportunities after applying for the openings Jobster recommended. I received my dream offer after that.',
    },
  ].map((testimonial, index) => {
    return (
      <Col xl={6} className='text-center' key={index}>
        <Card
          className='shadow-lg'
          cover={
            <div className='p-4'>
              <div className='text-left'>
                <img width={25} src={Quotation1Img} />
              </div>
              <div className='py-2 px-4'>
                <Typography.Text>{testimonial.review}</Typography.Text>
              </div>
              <div className='text-right'>
                <img width={25} src={Quotation2Img} />
              </div>
            </div>
          }
          style={{ backgroundColor: '#2E3C4D' }}
        >
          <Row>
            <Col span={6}>
              <div className='bg-gray text-center rounded-circle'>
                <img src={AvatarImg} className='w-100' />
              </div>
            </Col>
            <Col span={16} offset={2} className='text-left'>
              <Typography.Title level={5} className='text-white'>
                {testimonial.name}
              </Typography.Title>
              <div>
                <Typography.Text className='text-white'>
                  {testimonial.position}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className='text-white'>
                  at {testimonial.company}
                </Typography.Text>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {`${useIntl().formatMessage({
            id: 'resume-gpt::home::text::title-name',
          })}`}
        </title>
      </Helmet>
      {/* Hero Section */}
      <Row
        gutter={{ xs: 8, sm: 16, lg: 24, xl: 32 }}
        align='bottom'
        className='section-margin'
      >
        {/* Left Component */}
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 10, offset: 2 }}>
          <Typography.Title
            level={4}
            style={{ fontWeight: 'normal', marginBottom: '24px' }}
            className='font-xxl jobster-font-weight-800'
          >
            <Text
              id='jd-gpt::home::text::hero::title'
              defaultMessage='Five Minutes to Industry Standard Job Description'
              values={{
                gold: (chunks) => <span className='text-gold'>{chunks}</span>,
                blue: (chunks) => <span className='text-blue'>{chunks}</span>,
              }}
            />
          </Typography.Title>
          <Card className='mt-3 bg-light' style={{ minHeight: '423px' }}>
            {/* Add content for the left component here */}
          </Card>
        </Col>
        {/* Right Component */}
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 10, offset: 0 }}
          className='text-center'
        >
          <Card
            className='bg-light'
            style={{
              display: 'flex',
              height: '567px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '24px',
            }}
          >
            <Typography.Title
              level={4}
              style={{ fontWeight: 'normal', marginBottom: '24px' }}
              className='text-left'
            >
              <Text
                id='jd-gpt::home::text::hero::input::title'
                defaultMessage='Job Description'
              />
            </Typography.Title>
            <Input.TextArea
              style={{
                width: '100%',
                minHeight: '213px',
                padding: '16px',
                borderRadius: '8px',
                marginBottom: '24px',
              }}
              placeholder='Enter job description here...'
            />
            <NavLink to={Pages.RESUME_GPT_START}>
              <Button block type='primary' className='w-100'>
                <Text id='jd-gpt::home::text::hero::button::generate-free-job-description' />
              </Button>
            </NavLink>
            <Divider>or</Divider>
            <Button block onClick={() => uploadJDModal.current.showModal(Pages.DASHBOARD_RESUME_OVERVIEW)} className='w-100'>
              <Text id='jd-gpt::home::text::hero::button::upload-and-improve' />
            </Button>
          </Card>
        </Col>
      </Row>
      {/* Feature */}
      <Row
        gutter={{ xs: 8, sm: 16, lg: 24, xl: 32 }}
        className='section-margin'
      >
        {/* Left Component */}
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 10, offset: 2 }}>
          <Card
            className='bg-blue'
            style={{
              display: 'flex',
              height: '567px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
            }}
          ></Card>
        </Col>
        {/* Right Component */}
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 10, offset: 0 }}>
          <Card
            className='bg-gold'
            style={{
              display: 'flex',
              height: '567px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
            }}
          ></Card>
        </Col>
      </Row>
      {/* Success Story */}
      <Row justify='center' style={{ marginTop: '84px' }}>
        <Col span={24} className='text-center mb-5'>
          <Typography.Title className='font-xl jobster-font-weight-800'>
            <Text
              id='jd-gpt::home::text::success-story::title'
              values={{
                gold: (chunks) => <span className='text-gold'>{chunks}</span>,
                blue: (chunks) => <span className='text-blue'>{chunks}</span>,
              }}
            />
          </Typography.Title>
        </Col>
      </Row>
      <Row style={{ marginBottom: '84px' }}>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
          <Row justify='space-between'>{testimonials}</Row>
        </Col>
      </Row>
      {/* FAQ */}
      <Row justify='center' style={{ marginTop: '84px' }}>
        <Col span={24} className='text-center mb-5'>
          <Typography.Title className='font-xl jobster-font-weight-800'>
            <Text
              id='jd-gpt::home::text::faqs::title'
              values={{
                gold: (chunks) => <span className='text-gold'>{chunks}</span>,
                blue: (chunks) => <span className='text-blue'>{chunks}</span>,
              }}
            />
          </Typography.Title>
        </Col>
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, lg: 24, xl: 32 }}
        align='bottom'
        style={{ marginBottom: '84px' }}
      >
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 10, offset: 2 }}>
          <Card
            className='bg-light'
            style={{
              display: 'flex',
              height: '491px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
            }}
          ></Card>
        </Col>
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 10, offset: 0 }}
          className='my-auto p-4'
        >
          <Collapse
            accordion
            ghost
            items={getFaqs()}
            expandIcon={({ isActive }) =>
              isActive ? (
                <MinusCircleOutlined style={{ fontSize: '17px' }} />
              ) : (
                <PlusCircleOutlined style={{ fontSize: '17px' }} />
              )
            }
          />
        </Col>
      </Row>
      <UploadJD ref={uploadJDModal} redirect={Pages.JD_GPT_OVERVIEW} />
    </React.Fragment>
  );
}

export default Home;