import { Col, Row } from 'antd';
import React from 'react';
import Iframe from 'react-iframe'

function CareerPath() {
    return (
        <div className='col-10 offset-1 mt-5 position-relative w-100 h-100'>
            <Row className='mb-4 h-100'>
                <Col span={24}>
                    <Iframe url='https://public.tableau.com/views/Career_16419190489060/Dashboard12?:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Fpublic.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=yes&:animate_transition=yes&:display_static_image=yes&:display_spinner=no&:display_overlay=yes&:display_count=yes&:language=en-US&:loadOrderID=0'
                        width='100%'
                        height='100%'
                        position='relative'
                        frameBorder='0'
                    />
                </Col>
            </Row>
        </div>
    );
}

export default CareerPath;
