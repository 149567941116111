import React, { useState } from 'react';
import { userContext } from '../utils/user';
import { getUser } from '../utils/auth';
import DashboardHeader from './dashboard/header';
import GuardedRoute from './guarded-route';
import DashboardHome from '../pages/dashboard/home';
import DashboardResumes from '../pages/dashboard/resumes';
import DashboardJobs from '../pages/dashboard/jobs';
import DashboardInterviews from '../pages/dashboard/interviews';
import DashboardProfile from '../pages/dashboard/profile';
import CareerPath from '../pages/dashboard/career-path';
import WeeklyTrend from '../pages/dashboard/weekly-trend';
import DashboardFooter from './dashboard/footer';
import TestPrompt from '../pages/dashboard/test-prompt';

function DashboardLayout() {
    const [user, setUser] = useState(getUser());
    const updateUser = user => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            setUser(user);
        } else {
            setUser(false);
        }
    }

    return <userContext.Provider value={{ user: user, updateUser: updateUser }}>
        <DashboardHeader username={user.fullname} />
        <div className='vh-100 d-flex flex-column'>
            <GuardedRoute path='/dashboard/test_prompt' component={TestPrompt} />
            <GuardedRoute path='/dashboard/resumes' component={DashboardResumes} />
            <GuardedRoute path='/dashboard/jobs' component={DashboardJobs} />
            <GuardedRoute path='/dashboard/interviews' component={DashboardInterviews} />
            <GuardedRoute path='/dashboard/profile' component={DashboardProfile} />
            <GuardedRoute path='/dashboard/career-path' component={CareerPath} />
            <GuardedRoute path='/dashboard/weekly-trend' component={WeeklyTrend} />
            <GuardedRoute exact path="/dashboard" component={DashboardHome} />
            <DashboardFooter />
        </div>
    </userContext.Provider>
}

export default DashboardLayout;