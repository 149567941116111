import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PublicLayout from './components/public-layout';
import DashboardLayout from './components/dashboard-layout';
import ScrollToTop from './components/scroll-to-top';

import ReactGA from 'react-ga4'; // npm install react-ga4
const TRACKING_ID = 'G-X3P44F9C75'; // GA4 Tracking ID, change to which ever account is associated
ReactGA.initialize(TRACKING_ID);

function App() {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname+window.location.search });
    return (
        <div className='container-fluid d-flex flex-column vh-100 px-0'>
            <Router>
                <ScrollToTop />
                <Switch>
                    <Route path='/dashboard' component={DashboardLayout} />
                    <Route path='/' component={PublicLayout} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
