import { Alert, Col, Empty, List, Progress, Row, Skeleton, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Text from '../../components/text';
import { APIs, ExternalLink, Pages } from '../../utils/constants';
import { userContext } from '../../utils/user';
import defaultCompany from '../../assets/images/default-company.png';
import Button from '../../components/button';
import './home.scss';
import moment from 'moment';
import { Cursor, Envelope, GraphUp, People } from 'react-bootstrap-icons';
import { getFileLink } from '../../utils/get-file-link';

function DashboardHome() {
    const history = useHistory();
    const user = useContext(userContext);

    // States
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [resume, setResume] = useState(null);
    const [todayInterviews, setTodayInterviews] = useState([]);
    const [upcomingInterviews, setUpcomingInterviews] = useState([]);

    // Styles
    const styles = {
        height80: {
            height: '50px'
        },
        companyImage: {
            maxHeight: '50px',
            maxWidth: '100%'
        },
        progressSkeleton: {
            height: '90px',
            width: '90px'
        }
    }

    useEffect(() => {
        const fetchDashboardData = async () => {
            setLoading(true);

            try {
                const response = await fetch(APIs.GET_DASHBOARD, {
                    method: 'POST',
                    body: JSON.stringify({
                        user: user.user.id,
                        token: user.user.deviceIdentity
                    })
                });

                if (response.ok) {
                    const r = await response.json();
                    setJobs(r.jobs);
                    setResume(r.resume);
                    const todayInterviews = [];
                    const upcomingInterviews = [];

                    r.interviews?.forEach(interview => {
                        if (moment(interview.date + ' ' + interview.time).isSame(moment(), 'day')) {
                            todayInterviews.push(interview);
                        } else {
                            upcomingInterviews.push(interview);
                        }
                    });

                    setTodayInterviews(todayInterviews);
                    setUpcomingInterviews(upcomingInterviews);
                    setLoading(false);
                } else {
                    setErrorMessage('Something went wrong');
                }
            } catch {
                setErrorMessage('Something went wrong');
            }
        };

        fetchDashboardData();
    }, []);

    const getInterviewUI = interviews => {
        return interviews.map(interview =>
            <div key={interview.id} className='mb-2'>
                <Typography.Text className='d-block'>
                    {moment(interview.date + ' ' + interview.time).format('h:mm a | dddd, MMMM D, YYYY')}
                </Typography.Text>
                <Typography.Text>
                    {interview.company + ' | ' + interview.position}
                </Typography.Text>
            </div>
        );
    }

    const getResumeQuality = percentage => {
        if (percentage > 95) {
            return 'A+';
        } else if (percentage > 90) {
            return 'A';
        } else if (percentage > 85) {
            return 'A-';
        } else if (percentage > 80) {
            return 'B+';
        } else if (percentage > 75) {
            return 'B';
        } else if (percentage > 70) {
            return 'B-';
        } else if (percentage > 65) {
            return 'C+';
        } else if (percentage > 60) {
            return 'C';
        } else if (percentage > 55) {
            return 'C-';
        } else {
            return 'F';
        }
    }

    return (
        <div className='col-10 offset-1 mt-5 mb-4 pt-5'>
            {errorMessage &&
                <Alert
                    className='position-absolute w-100 text-danger text-center'
                    style={{ left: 0, top: 0 }}
                    message={errorMessage}
                    type='error'
                    showIcon={false}
                    banner
                />
            }
            <Row className='mb-4' gutter={[20, 22]}>
                <Col className='gutter-row' span={16}>
                    <div className='mb-5'>
                        <Typography.Title level={3}>
                            <Text id='dashboard::home::title::jobs' />
                        </Typography.Title>
                        <List
                            className='bg-gray'
                            itemLayout='horizontal'
                            dataSource={loading ? Array(6).fill(0) : jobs}
                            renderItem={(job, index) => (
                                <List.Item className='mx-4 position-relative'>
                                    <div className='col-12 d-lg-flex p-0 position-inherit' key={index}>
                                        <div className='col-lg-2 text-center d-flex' style={styles.height80}>
                                            {
                                                loading ?
                                                    <Skeleton.Image className='m-auto' /> :
                                                    <img className='m-auto' src={job.image ? job.image : defaultCompany} style={styles.companyImage} />
                                            }
                                        </div>
                                        <div className='col-lg-8 mt-3 my-lg-auto text-center text-lg-left'>
                                            <Typography.Title level={5}>
                                                {loading ? <Skeleton.Button className='w-75' active='true' /> :
                                                    <>
                                                        {job.position} <Typography.Text className='font-weight-normal'>| {job.company}</Typography.Text>
                                                    </>}
                                            </Typography.Title>
                                            <div className='mt-1'>
                                                <Typography.Text>
                                                    {loading ?
                                                        <Skeleton.Button className='w-50' active='true' size='small' /> :
                                                        job.location
                                                    }
                                                </Typography.Text>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 mb-3 my-lg-auto text-center'>
                                            <a href={job.link} target='_blank' rel='noopener noreferrer'>
                                                {loading ?
                                                    <Skeleton.Button className='w-100' active='true' size='small' /> :
                                                    <Button size='sm'>
                                                        <Text id='dashboard::home::button::apply' />
                                                    </Button>
                                                }
                                            </a>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                    <div>
                        <Typography.Title level={3}>
                            <Text id='dashboard::home::title::resume' />
                        </Typography.Title>
                        {<Row className='bg-gray py-3 px-4'>
                            {loading && <>
                                <Col span={13} offset={3}>
                                    <Skeleton.Button className='w-100' active='true' size='small' />
                                    <Skeleton.Button className='w-100 mt-2' active='true' size='small' />
                                    <Skeleton.Button className='w-100 mt-2' active='true' size='small' />
                                </Col>
                                <Col span={5} className='text-right'>
                                    <Skeleton.Button style={styles.progressSkeleton} active='true' shape='circle' />
                                </Col>
                            </>}
                            {!loading && !resume && <div className='m-auto'>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                <Button size='small' onClick={() => history.push(Pages.DASHBOARD_RESUMES)}>
                                    <Text id='dashboard::home::button::manage-resumes' />
                                </Button>
                            </div>}
                            {!loading && resume && <>
                                <Col span={13} offset={3}>
                                    <Typography.Title level={4} className='mb-1 font-weight-normal'>
                                        <Text id='dashboard::home::resume::description1' />
                                    </Typography.Title>
                                    <Typography.Title level={4} className='mt-0 mb-1 font-weight-light'>
                                        <Text id='dashboard::home::resume::description2' values={{
                                            resume: () => <a className='text-primary' onClick={() => getFileLink(resume.id, history, setErrorMessage)}>
                                                {resume.name}
                                            </a>,
                                            grade: msg => <Typography.Text type={resume.percentage > 80 ? 'success' : resume.percentage > 60 ? 'warning' : 'danger'}>{msg} {getResumeQuality(resume.percentage)}</Typography.Text>
                                        }} />
                                    </Typography.Title>
                                    <Typography.Title level={4} className='mt-0 font-weight-light'>
                                        <Text id='dashboard::home::resume::description3' values={{
                                            percentage: () => resume.percentage,
                                            percentage2: () => 100 - resume.percentage
                                        }} />
                                    </Typography.Title>
                                </Col>
                                <Col span={5} className='text-right'>
                                    <Progress
                                        type='circle'
                                        percent={resume.percentage}
                                        size={90}
                                        format={() => resume.percentage + '%'}
                                        strokeColor={resume.percentage > 80 ? '#ADDBB2' : resume.percentage > 60 ? '#FFD191' : '#FAAAA5'}
                                    />
                                </Col>
                            </>}
                        </Row>}
                    </div>
                </Col>
                <Col className='gutter-row' span={8}>
                    <div className='mb-5'>
                        <Typography.Title level={3}>
                            <Text id='dashboard::home::title::interviews' />
                        </Typography.Title>
                        <div className='bg-gray py-3 px-4'>
                            {todayInterviews.length == 0 && upcomingInterviews.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            {todayInterviews.length != 0 && <div className='mb-4'>
                                <Typography.Title level={5} underline>
                                    <Text id='dashboard::home::sub-title::today' />
                                </Typography.Title>
                                {getInterviewUI(todayInterviews)}
                            </div>}
                            {upcomingInterviews.length != 0 && <>
                                <Typography.Title level={5} underline>
                                    <Text id='dashboard::home::sub-title::upcoming' />
                                </Typography.Title>
                                {getInterviewUI(upcomingInterviews)}
                            </>}
                            <div className='text-center'>
                                <Button size='small' onClick={() => history.push(Pages.DASHBOARD_INTERVIEWS)}>
                                    <Text id='dashboard::home::button::manage-interviews' />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Typography.Title level={3}>
                            <Text id='dashboard::home::title::toolkit' />
                        </Typography.Title>
                        <div className='bg-gray py-3 px-4'>
                            <a href={ExternalLink.PIKLET} target='_blank' rel='noopener noreferrer' className='d-none d-lg-inline text-blue'>
                                <Typography.Title level={5} className='font-weight-normal mb-2 cursor-pointer text-blue-with-hover'>
                                    <Envelope className='mr-3' />
                                    <Text id='dashboard::home::toolkit::email-communication' />
                                </Typography.Title>
                            </a>
                            <NavLink to={Pages.CAREER_PATH} className='text-blue'>
                                <Typography.Title level={5} className='font-weight-normal mb-2 cursor-pointer text-blue-with-hover'>
                                    <Cursor className='mr-3' />
                                    <Text id='dashboard::home::toolkit::career-path' />
                                </Typography.Title>
                            </NavLink>
                            <NavLink to={Pages.WEEKLY_TREND} className='text-blue'>
                                <Typography.Title level={5} className='font-weight-normal mb-2 cursor-pointer text-blue-with-hover'>
                                    <GraphUp className='mr-3' />
                                    <Text id='dashboard::home::toolkit::weekly-market-trend' />
                                </Typography.Title>
                            </NavLink>
                            <a href={ExternalLink.LOEWE_CALENDAR} target='_blank' rel='noopener noreferrer' className='d-none d-lg-inline text-blue'>
                                <Typography.Title level={5} className='font-weight-normal my-2 cursor-pointer text-blue-with-hover'>
                                    <People className='mr-3' />
                                    <Text id='dashboard::home::toolkit::free-consultation' />
                                </Typography.Title>
                            </a>
                        </div>
                    </div>

                </Col>
            </Row>
        </div>
    );
}

export default DashboardHome;
