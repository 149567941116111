import React from 'react';
import { APIs, Pages } from './constants';
import { getUser } from './auth';
import Text from '../components/text';

// Hook
export async function getFileLink(fid, history, setErrorMessage) {
    const user = getUser();

    try {
        const response = await fetch(APIs.GET_FILE, {
            method: 'POST', body: JSON.stringify({
                user: user.id,
                token: user.deviceIdentity,
                fid: fid
            })
        });
        const r = await response.json();
        if (response.ok) {
            window.open(r.url, '_blank');
            return;
        } else if (response.status == 403) {
            localStorage.removeItem('user');
            history.push(Pages.HOME);
            return;
        } else {
            return setErrorMessage(<Text id='get-file-link::error::general' />);
        }
    } catch (error) {
        return setErrorMessage(<Text id='get-file-link::error::general' />);
    }
}
