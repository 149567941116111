import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Typography, Drawer, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import Text from './text';
import JobsterBlueIcon from '../assets/images/jobster-icon-blue.png';
import JobsterBlueText from '../assets/images/jobster-text-blue.png';
import JobsterWhiteIcon from '../assets/images/jobster-icon-white.png';
import JobsterWhiteText from '../assets/images/jobster-text-white.png';
import defaultUserIcon from '../assets/images/default-user.png';
import { Pages } from '../utils/constants';
import Button from './button';
import { isDesktop } from '../utils/window';
import { List } from 'react-bootstrap-icons';
import Login from './modal/login.js';
import classNames from 'classnames';

function Header() {
    const [user, setUser] = useState(null);
    const [open, setOpen] = useState(false);
    const loginModal = useRef();

    const nonDesktopClass = classNames('shadow-sm', 'pt-3', 'pb-2', 'px-3', 'w-100', { 'bg-blue': !isDesktop() });

    useEffect(() => {
        const fetchUser = () => {
            const user = JSON.parse(localStorage.getItem('user'));
            setUser(user);
        };

        fetchUser();
    }, []);

    const onLoggedIn = () => {
        setUser(JSON.parse(localStorage.getItem('user')));
    }

    const navigationLinks = [
        { to: Pages.FIND_JOBS, id: 'header::navigation::find-jobs' },
        { to: Pages.FIND_TALENT, id: 'header::navigation::find-talent' },
        { to: Pages.RESUME_GPT, id: 'header::navigation::resume-generator' },
        { to: Pages.EBOOK, id: 'header::navigation::ebook' },
        { to: Pages.ABOUT_US, id: 'header::navigation::about' }
    ];

    const renderNavLink = (link, index) => (
        <Col key={index} className='text-center my-auto'>
            <NavLink to={link.to} className='text-blue'>
                <Typography.Title className="font-md font-weight-normal m-0">
                    <Text id={link.id} />
                </Typography.Title>
            </NavLink>
        </Col>
    );

    return (
        <header>
            <Row className={nonDesktopClass}>
                <Col xs={0} sm={0} lg={6} xl={{ span: 4, offset: 2 }}>
                    <NavLink to={Pages.HOME}>
                        <img src={JobsterBlueIcon} width='40' alt="Jobster Icon" />
                        <img src={JobsterBlueText} height='30' className='ml-2 mt-2' alt="Jobster Text" />
                    </NavLink>
                </Col>
                <Col xs={0} sm={0} lg={12} xl={{ span: 10, offset: 1 }} className='my-auto'>
                    <Row justify="space-evenly" gutter={[24, 0]}>
                        {navigationLinks.map(renderNavLink)}
                    </Row>
                </Col>
                <Col xs={0} sm={0} lg={6} xl={5}>
                    <Row justify="end" gutter={24}>
                        <Col className='text-right my-auto'>
                            {user ? (
                                <NavLink to={Pages.DASHBOARD} className='text-blue'>
                                    <img src={user.profile_image || defaultUserIcon} alt='User Profile' className='rounded-circle' width='45' height='45' />
                                </NavLink>
                            ) : (
                                <Button onClick={() => loginModal.current.showModal()} className='ml-lg-2'>
                                    <Typography.Title className='font-md font-weight-normal m-0 text-white'>
                                        <Text id='header::navigation::sign-in' />
                                    </Typography.Title>
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ span: 12, offset: 6 }} sm={{ span: 12, offset: 6 }} lg={0} xl={0} className='text-center'>
                    <NavLink to={Pages.HOME}>
                        <img src={JobsterWhiteIcon} width='25' alt="Jobster Icon" />
                        <img src={JobsterWhiteText} height='20' className='ml-2 mt-1' alt="Jobster Text" />
                    </NavLink>
                </Col>
                <Col xs={5} sm={5} lg={0} xl={0} className='text-right'>
                    <List onClick={() => setOpen(true)} />
                    <Drawer
                        title={<NavLink to={Pages.HOME}><img src={JobsterBlueIcon} width='40' alt="Jobster Icon" /><img src={JobsterBlueText} height='30' className='ml-2 mt-2' alt="Jobster Text" /></NavLink>}
                        placement="right"
                        closable={true}
                        onClose={() => setOpen(false)}
                        open={open}
                    >
                        <Menu mode="vertical" onClick={() => setOpen(false)}>
                            {navigationLinks.map((link, index) => (
                                <Menu.Item key={index}>
                                    <NavLink to={link.to} className='text-blue h-100 d-flex align-items-center'>
                                        <Typography.Title className="font-md font-weight-normal m-0">
                                            <Text id={link.id} />
                                        </Typography.Title>
                                    </NavLink>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Drawer>
                </Col>
            </Row>
            <Login ref={loginModal} onLoggedIn={onLoggedIn} />
        </header>
    );
}

export default Header;
