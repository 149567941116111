import React from 'react';
import { Modal, Alert } from 'antd';
import PropTypes from 'prop-types';
import Button from '../button.js';
import './modal.scss';

ModalWithAlert.propTypes = {
    title: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    className: PropTypes.string,
    footer: PropTypes.array,
    children: PropTypes.object,
    alerts: PropTypes.array,
    alertsOption: PropTypes.object
}

function ModalWithAlert({ title = '', visible = false, onCancel, className, footer, children, alerts = [], alertsOption = {} }) {
    const alertElements = alerts.map((alert, index) => (
        <Alert
            key={index}
            type={alert.type}
            message={alert.content}
            description={alert.description}
            banner
            closable
            action={
                alert.actionText && (
                    <Button
                        solid={false}
                        size='sm'
                        className='px-2 ml-3'
                        onClick={(e) => alert.action(e, alerts)}
                        disabled={alertsOption.disableAllActions || false}
                    >
                        {alert.actionText}
                    </Button>
                )
            }
        />
    ));

    return (
        <Modal
            destroyOnClose={true}
            title={title}
            open={visible}
            onCancel={onCancel}
            className={`${className} modal-with-alert`}
            footer={footer}
        >
            <div className='modal-alert-container'>
                {alertElements.length > 0 && alertElements}
            </div>
            {children}
        </Modal>
    );
}

export default ModalWithAlert;
