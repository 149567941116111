import React, { useState, useContext } from 'react';
import { Alert, List, Pagination, Skeleton, Typography } from 'antd';
import { useEffect } from 'react';
import { APIs } from '../../utils/constants';
import { userContext } from '../../utils/user';
import { Trash } from 'react-bootstrap-icons';
import defaultCompany from '../../assets/images/default-company.png';
import Text from '../../components/text';
import Button from '../../components/button';

function DashboardJobs() {
    const user = useContext(userContext);

    // CSS
    const height80 = {
        height: '80px'
    }
    const companyImage = {
        maxHeight: '80px',
        maxWidth: '100%'
    }
    const trashCSS = {
        top: '0',
        right: '1rem',
        fontSize: '15px'
    }

    // State
    const [loadingJobListing, setLoadingJobListing] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [savedJobs, setSavedJobs] = useState([]);
    const [displayedJobs, setDisplayedJobs] = useState([]);
    const [error, setError] = useState(false);

    // Actions
    const getErrorMessage = <Text id='dashboard::jobs::error::get' />;
    const deleteErrorMessage = <Text id='dashboard::jobs::error::delete' />;

    useEffect(async () => {
        setLoadingJobListing(true);
        try {
            const response = await fetch(APIs.GET_SAVED_JOBS, {
                method: 'POST', body: JSON.stringify({
                    user: user.user.id,
                    token: user.user.deviceIdentity
                })
            });
            if (response.ok) {
                const r = await response.json();
                setSavedJobs(r.items);
                setDisplayedJobs(r.items.slice(0, 10));
                setLoadingJobListing(false);
            } else {
                setError(getErrorMessage);
            }
        } catch {
            setError(getErrorMessage);
        }
    }, []);

    const updateDisplayJobs = page => {
        setCurrentPage(page);
        setDisplayedJobs(savedJobs.slice((page - 1) * 10, page * 10));
    }

    const unsaveJob = async jobId => {
        try {
            const response = await fetch(APIs.UNSAVE_JOB, {
                method: 'POST', body: JSON.stringify({
                    user: user.user.id,
                    token: user.user.deviceIdentity,
                    job: jobId
                })
            });
            if (response.ok) {
                let temp = savedJobs.filter(job => job.id !== jobId);
                setSavedJobs([...temp]);
                setDisplayedJobs(temp.slice((currentPage - 1) * 10, currentPage * 10));
            } else {
                setError(deleteErrorMessage);
            }
        } catch (error) {
            setError(deleteErrorMessage);
        }
    }

    return (
        <div className='col-8 offset-2 mt-5 mb-4 pt-5 position-relative'>
            {error &&
                <Alert
                    className='position-absolute w-100 text-danger text-center'
                    style={{ left: 0, top: 0 }}
                    message={error}
                    type='error'
                    showIcon={false}
                    banner
                />
            }
            <Typography.Title level={3}>
                <Text id='dashboard::jobs::text::title' />
            </Typography.Title>
            <List
                itemLayout='horizontal'
                dataSource={loadingJobListing ? Array(6).fill(0) : displayedJobs}
                renderItem={(job, index) => (
                    <List.Item className='mx-4 position-relative'>
                        <div className='col-12 d-lg-flex px-0 py-3 position-inherit' key={index}>
                            <div className='col-lg-2 text-center d-flex' style={height80}>
                                {
                                    loadingJobListing ?
                                        <Skeleton.Image className='m-auto' /> :
                                        <img className='m-auto' src={job.image ? job.image : defaultCompany} style={companyImage} />
                                }
                            </div>
                            <div className='col-lg-8 mt-3 my-lg-auto text-center text-lg-left'>
                                <Typography.Title level={5}>
                                    {loadingJobListing ? <Skeleton.Button className='w-75' active='true' /> : job.position}
                                </Typography.Title>
                                <Typography.Text>
                                    {loadingJobListing ? <Skeleton.Button className='w-50' active='true' size='small' /> : job.company}
                                </Typography.Text>
                                <div className='mt-2'>
                                    <Typography.Text>
                                        {loadingJobListing ?
                                            <Skeleton.Button className='w-50' active='true' size='small' /> :
                                            <Text id='dashboard::jobs::text::saved' values={{
                                                timestamp: () => job.timestamp
                                            }} />}
                                    </Typography.Text>
                                </div>
                            </div>
                            <div className='col-lg-2 mb-3 my-lg-auto text-center'>
                                <a href={job.link} target='_blank' rel='noopener noreferrer'>
                                    {loadingJobListing ?
                                        <Skeleton.Button className='w-100' active='true' size='small' /> :
                                        <Button size='sm'>
                                            <Text id='result::job-listing::button::apply' />
                                        </Button>
                                    }
                                </a>
                            </div>
                            <div className='position-absolute mt-3' style={trashCSS}>
                                {loadingJobListing ?
                                    <Skeleton.Button active='true' size='small' /> :
                                    <Trash className='cursor-pointer mr-2' onClick={() => unsaveJob(job.id)} />
                                }
                            </div>
                        </div>
                    </List.Item>
                )}
            />
            {savedJobs.length > 0 && <div className='text-center my-4'>
                <Pagination
                    size='small'
                    current={currentPage}
                    total={savedJobs.length}
                    onChange={page => updateDisplayJobs(page)} />
            </div>}
        </div>
    );
}

export default DashboardJobs;
