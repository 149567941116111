import React, { useState } from 'react';
import { Row, Typography, Input, List, Col, Spin } from 'antd';
import Button from '../../components/button';

function TestPrompt() {
    const resumeIds = [
        '4b999da0-e0ca-4e56-9fed-37e9949ad08b',
        'a3e6a724-fb0d-4e66-adf6-c5a4b69f4486',
        'eecc8122-1b97-47a5-a15f-5984295ec75a',
        '4552945b-2968-469f-82a4-c8333a496615',
        '4d19fa1e-ea2a-423b-9308-66324108ba72',
        'a5897455-d1ed-47f5-adce-ce6bc9ba17b6',
        'b245b92a-8382-4664-9885-311c35d30b38',
        'b63e730f-5d4b-4308-9dfa-5ae11ebde800',
        'a3c5a7c5-cf0e-4506-a1f6-62878de161c7',
        '6dd42716-3157-44d5-bb07-076a272c7c32'
    ];

    const [prompt, setPrompt] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleTextChange = (e) => {
        setPrompt(e.target.value);
    };

    const submitRequests = async () => {
        setLoading(true);
        setResults([]);
        for (const id of resumeIds) {
            try {
                const response = await fetch(`https://api.jobster.io/resume/${id}/test-prompt`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ prompt }),
                });
                const data = await response.json();
                setResults(prevResults => [...prevResults, { id, data }]);
            } catch (error) {
                setResults(prevResults => [...prevResults, { id, error: error.message }]);
            }
        }
        setLoading(false);
    };

    return (
        <div className="col-10 offset-1 mt-3 position-relative">
            <Row className="mb-5">
                <Typography.Title level={2}>Your Prompt</Typography.Title>
                <Input.TextArea rows={8} value={prompt} onChange={handleTextChange} />
            </Row>
            <Row className="mb-5">
                <Col span={24}>
                    <Typography.Title level={2}>Results</Typography.Title>
                    <List
                        bordered
                        dataSource={results}
                        renderItem={(item) => (
                            <List.Item>
                                <Typography.Text code>{item.id}</Typography.Text>: {JSON.stringify(item.data || item.error)}
                            </List.Item>
                        )}
                    />
                    {loading && (
                        <div className="text-center">
                            <Spin />
                        </div>
                    )}
                </Col>
            </Row>
            <Row className="mb-5">
                <Button onClick={submitRequests} className="m-auto">
                    Submit
                </Button>
            </Row>
        </div>
    );
}

export default TestPrompt;