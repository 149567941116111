import React, { useState, forwardRef, useImperativeHandle, useContext } from 'react';
import { userContext } from '../../utils/user';
import { APIs } from '../../utils/constants';
import { AutoComplete, Tag } from 'antd';
import ModalWithAlert from './modal';
import Button from '../button';
import Text from '../text';

const EditSkills = forwardRef((_, ref) => {
    const user = useContext(userContext);

    // States
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalAlerts, setModalAlerts] = useState([]);
    const [options, setOptions] = useState([]);
    const [currentSkills, setCurrentSkills] = useState(user.user.skills || []);
    const [searchValue, setSearchValue] = useState('');

    // Actions
    useImperativeHandle(ref, () => ({
        showModal() {
            setIsModalVisible(true);
        }
    }))

    const onSkillSearch = async searchText => {
        setSearchValue(searchText);
        if (searchText) {
            try {
                const response = await fetch(APIs.SKILL_SUGGESTIONS, {
                    method: 'POST', body: JSON.stringify({
                        user: user.user.id,
                        token: user.user.deviceIdentity,
                        search: searchText,
                        filter: currentSkills
                    })
                });
                const r = await response.json();
                const existInCurrentKills = currentSkills.findIndex(skill => {
                    return skill.toLowerCase() === searchText.toLowerCase();
                });
                const existInResponse = r.items.findIndex(skill => {
                    return skill.toLowerCase() === searchText.toLowerCase();
                });

                let tempOptions = r.items.map(skill => { return { value: skill } });
                if (existInCurrentKills === -1 && existInResponse === -1) {
                    tempOptions = [{value: searchText}, ...tempOptions];
                }
                setOptions(tempOptions);
            } catch (error) {
                // console.log(error);
            }
        } else {
            setOptions([]);
        }
    };

    const onSkillSelect = skill => {
        setSearchValue('');
        setOptions([]);
        setCurrentSkills([
            ...currentSkills,
            skill
        ]);
    };

    const onTagClose = skill => {
        const index = currentSkills.indexOf(skill);
        if (index > -1) {
            currentSkills.splice(index, 1);
        }
        setCurrentSkills([...currentSkills]);
    }

    const handleModalClose = () => {
        setCurrentSkills(user.user.skills);
        setModalAlerts([]);
        setIsModalVisible(false);
    }

    const handleErrorAlerts = () => {
        setModalAlerts([
            ...modalAlerts,
            {
                type: 'error',
                content: <Text id='modal::edit-skills::message::error' />
            }
        ]);
    };

    const handleModalSubmit = async () => {
        try {
            const response = await fetch(APIs.EDIT_SKILLS, {
                method: 'POST', body: JSON.stringify({
                    user: user.user.id,
                    token: user.user.deviceIdentity,
                    skills: currentSkills
                })
            });
            if (response.ok) {
                const userBackup = { ...user.user };
                userBackup['skills'] = currentSkills;
                user.updateUser(userBackup);
                handleModalClose();
            } else {
                handleErrorAlerts();
            }
        } catch (error) {
            handleErrorAlerts();
        }
    }

    return (
        <ModalWithAlert
            title={<Text id='modal::edit-skills::title::edit-skills' />}
            visible={isModalVisible}
            onCancel={handleModalClose}
            alerts={modalAlerts}
            footer={[
                <Button key='submit' onClick={handleModalSubmit}>
                    <Text id='modal::edit-skills::button::save' />
                </Button>
            ]}>
            <div className='text-gray mt-3'>
                <div className='col-12 p-0 mb-4'>
                    <AutoComplete
                        value={searchValue}
                        className='w-100'
                        options={options}
                        onSelect={onSkillSelect}
                        onSearch={onSkillSearch}
                    />
                </div>
                <div>
                    {
                        currentSkills?.map(
                            skill =>
                                <Tag closable key={skill} color='blue' onClose={() => onTagClose(skill)}>
                                    {skill}
                                </Tag>
                        )
                    }
                </div>
            </div>
        </ModalWithAlert>
    );
})

export default EditSkills;
