import { Col, Row } from 'antd';
import React from 'react';
import Iframe from 'react-iframe'

function WeeklyTrend() {
    return (
        <div className='col-10 offset-1 mt-5 position-relative w-100 h-100'>
            <Row className='mb-4 h-100'>
                <Col span={24}>
                    <Iframe url='https://datastudio.google.com/embed/u/0/reporting/8cad77b5-3195-417e-9590-5b775b8230c8/page/S9b5C'
                        width='100%'
                        height='100%'
                        position='relative'
                        frameBorder='0'
                    />
                </Col>
            </Row>
        </div>
    );
}

export default WeeklyTrend;
