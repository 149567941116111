import React, { useContext, useRef } from 'react';
import { Tag, Typography } from 'antd';
import { Pencil } from 'react-bootstrap-icons';
import { userContext } from '../../utils/user';
import Text from '../../components/text';
import EditName from '../../components/modal/edit-name';
import EditPhone from '../../components/modal/edit-phone';
import EditPassword from '../../components/modal/edit-password';
import EditWorkExperience from '../../components/modal/edit-work-experience.js';
import EditSkills from '../../components/modal/edit-skills';

function DashboardProfile() {
    const user = useContext(userContext);
    const editNameModal = useRef();
    const editPhoneModal = useRef();
    const editPassword = useRef();
    const editWorkExperience = useRef();
    const editSkills = useRef();

    return (
        <div className='row'>
            <div className='col-8 offset-2 d-flex mt-5 mb-4'>
                <div className='col-6'>
                    <div className='bg-gray px-5 py-4'>
                        <Typography.Title level={3}>
                            <Text id='dashboard::profile::contact-info::title' />
                        </Typography.Title>
                        <div className='d-flex mt-4'>
                            <div className='p-0 col-4'>
                                <Typography.Title level={5} className='font-weight-normal'>
                                    <Text id='dashboard::profile::contact-info::sub-title::email' />
                                </Typography.Title>
                            </div>
                            <div className='p-0 col-7'>
                                <Typography.Title level={5} className='font-weight-normal'>
                                    {user.user.email}
                                </Typography.Title>
                            </div>
                        </div>
                        <div className='d-flex mt-4'>
                            <div className='p-0 col-4'>
                                <Typography.Title level={5} className='font-weight-normal'>
                                    <Text id='dashboard::profile::contact-info::sub-title::name' />
                                </Typography.Title>
                            </div>
                            <div className='p-0 col-7'>
                                <Typography.Title level={5} className='font-weight-normal'>
                                    {user.user.fullname}
                                </Typography.Title>
                            </div>
                            <div className='p-0 col-1 text-right'>
                                <Pencil className='cursor-pointer' onClick={() => editNameModal.current.showModal()} />
                            </div>
                        </div>
                        <div className='d-flex mt-4'>
                            <div className='p-0 col-4'>
                                <Typography.Title level={5} className='font-weight-normal'>
                                    <Text id='dashboard::profile::contact-info::sub-title::phone' />
                                </Typography.Title>
                            </div>
                            <div className='p-0 col-7'>
                                <Typography.Title level={5} className='font-weight-normal'>
                                    {user.user.phone}
                                </Typography.Title>
                            </div>
                            <div className='p-0 col-1 text-right'>
                                <Pencil className='cursor-pointer' onClick={() => editPhoneModal.current.showModal()} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='bg-gray px-5 py-4'>
                        <Typography.Title level={3}>
                            <Text id='dashboard::profile::security::title' />
                        </Typography.Title>
                        <div className='d-flex mt-4 mb-5'>
                            <div className='p-0 col-4'>
                                <Typography.Title level={5} className='font-weight-normal'>
                                    <Text id='dashboard::profile::security::title::password' />
                                </Typography.Title>
                            </div>
                            <div className='p-0 col-7'>
                                <Typography.Title level={5} className='font-weight-normal mt-1'>
                                    **********
                                </Typography.Title>
                            </div>
                            <div className='p-0 col-1 text-right'>
                                <Pencil className='cursor-pointer' onClick={() => editPassword.current.showModal()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-8 offset-2 d-flex mb-4'>
                <div className='col-12'>
                    <div className='bg-gray px-5 py-4'>
                        <div className='d-flex'>
                            <div className='col-9 p-0'>
                                <Typography.Title level={3}>
                                    <Text id='dashboard::profile::work-experience::title' />
                                </Typography.Title>
                            </div>
                            <div className='col-3 p-0 text-right mt-2'>
                                <Pencil className='cursor-pointer' onClick={() => editWorkExperience.current.showModal()} />
                            </div>
                        </div>
                        <div className='d-flex mt-4'>
                            <div className='col-5 d-flex p-0'>
                                <Typography.Title level={5} className='font-weight-normal m-0 mr-1'>
                                    <Text id='dashboard::profile::work-experience::sub-title::company' />
                                </Typography.Title>
                                <Typography.Title level={5} className='font-weight-normal m-0'>
                                    {user.user.experience?.company}
                                </Typography.Title>
                            </div>
                            <div className='col-5 d-flex p-0'>
                                <Typography.Title level={5} className='font-weight-normal m-0 mr-1'>
                                    <Text id='dashboard::profile::work-experience::sub-title::position' />
                                </Typography.Title>
                                <Typography.Title level={5} className='font-weight-normal m-0'>
                                    {user.user.experience?.position}
                                </Typography.Title>
                            </div>
                            <div className='col-2 d-flex p-0'>
                                <Typography.Title level={5} className='font-weight-normal m-0 mr-1'>
                                    <Text id='dashboard::profile::work-experience::sub-title::salary' />
                                </Typography.Title>
                                <Typography.Title level={5} className='font-weight-normal m-0'>
                                    {user.user.experience?.salary && new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(user.user.experience.salary)}
                                </Typography.Title>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-8 offset-2 d-flex mb-5'>
                <div className='col-12'>
                    <div className='bg-gray px-5 py-4'>
                        <div className='d-flex'>
                            <div className='col-9 p-0'>
                                <Typography.Title level={3}>
                                    <Text id='dashboard::profile::skills::title' />
                                </Typography.Title>
                            </div>
                            <div className='col-3 p-0 text-right mt-2'>
                                <Pencil className='cursor-pointer' onClick={() => editSkills.current.showModal()} />
                            </div>
                        </div>
                        <div className='d-flex mt-4'>
                            {
                                user.user.skills?.map(skill => <Tag key={skill} color='blue'>{skill}</Tag>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <EditName ref={editNameModal} />
            <EditPhone ref={editPhoneModal} />
            <EditPassword ref={editPassword} />
            <EditWorkExperience ref={editWorkExperience} />
            <EditSkills ref={editSkills} />
        </div>
    );
}

export default DashboardProfile;
