import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';
import { Pages } from '../../utils/constants';
import { userContext } from '../../utils/user.js';
import { BoxArrowRight } from 'react-bootstrap-icons';
import Text from '../text';
import JobsterBlueIcon from '../../assets/images/jobster-icon-blue.png';
import JobsterBlueText from '../../assets/images/jobster-text-blue.png';

function DashboardHeader() {
    const user = useContext(userContext);
    const logout = () => {
        localStorage.removeItem('user');
        user.updateUser();
    }

    return (
        <header>
            <Row className='shadow-sm p-3 w-100'>
                <Col span={4}>
                    <NavLink to={Pages.HOME}>
                        <img src={JobsterBlueIcon} width='45'></img>
                        <img src={JobsterBlueText} height='40' className='ml-4 mt-2'></img>
                    </NavLink>
                </Col>
                <Col offset={2} span={3} className='text-center my-auto'>
                    <NavLink to={Pages.DASHBOARD} className='text-blue'>
                        <Typography.Title level={5} className='font-weight-normal m-0'>
                            <Text id='dashboard::header::navigation::dashboard' />
                        </Typography.Title>
                    </NavLink>
                </Col>
                <Col span={3} className='text-center my-auto'>
                    <NavLink to={Pages.DASHBOARD_RESUMES} className='text-blue'>
                        <Typography.Title level={5} className='font-weight-normal m-0'>
                            <Text id='dashboard::header::navigation::resumes' />
                        </Typography.Title>
                    </NavLink>
                </Col>
                <Col span={3} className='text-center my-auto'>
                    <NavLink to={Pages.DASHBOARD_INTERVIEWS} className='text-blue'>
                        <Typography.Title level={5} className='font-weight-normal m-0'>
                            <Text id='dashboard::header::navigation::interviews' />
                        </Typography.Title>
                    </NavLink>
                </Col>
                <Col span={3} className='text-center my-auto'>
                    <NavLink to={Pages.DASHBOARD_PROFILE} className='text-blue'>
                        <Typography.Title level={5} className='font-weight-normal m-0'>
                            <Text id='dashboard::header::navigation::profile' />
                        </Typography.Title>
                    </NavLink>
                </Col>
                <Col span={6} className='text-right my-auto'>
                    <Typography.Text underline className='mr-3'>
                        {user.user.fullname?.split(' ')[0]}
                    </Typography.Text>
                    <Typography.Text className='cursor-pointer' onClick={logout}>
                        <Text id='header::button::logout' /> <BoxArrowRight />
                    </Typography.Text>
                </Col>
            </Row>
        </header>
    );
}
export default DashboardHeader;
