import React from 'react';
import { Row, Col, Typography } from 'antd';
import Text from '../text';

function DashboardFooter() {
    return (
        <footer className='mt-auto'>
            <Row className='w-100 text-center bg-light py-2 mt-auto'>
                <Col span={24}>
                    <Typography.Text>
                        <Text id='dashboard::footer::text::copy-right::line1' />
                    </Typography.Text>
                </Col>
                <Col span={24}>
                    <Typography.Text>
                        <Text id='dashboard::footer::text::copy-right::line2' />
                    </Typography.Text>
                </Col>
            </Row>
        </footer>
    )
}

export default DashboardFooter;