import React, { useRef } from 'react';
import Text from '../components/text';
import Button from '../components/button';
import Login from '../components/modal/login.js';
import rocketImg from '../assets/images/rocket.svg';

function ResetPasswordSuccess() {
    // Modal
    const loginModal = useRef();

    return (
        <div className='row my-5 vh-100'>
            <div className='col-lg-10 offset-lg-1 text-center'>
                <h1 className='font-size-small my-lg-5'>Your password has been changed</h1>
                <div className='my-5'>
                    <img src={rocketImg}></img>
                </div>
                <Button onClick={() => loginModal.current.showModal()}>
                    <Text id='reset-password-success::button::sign-in' />
                </Button>
            </div>
            <Login ref={loginModal} />
        </div>
    );
}

export default ResetPasswordSuccess;
