import React, { useState, forwardRef, useImperativeHandle, useContext } from 'react';
import { userContext } from '../../utils/user';
import { APIs } from '../../utils/constants';
import { Form, Input } from 'antd';
import ModalWithAlert from './modal';
import Button from '../button';
import Text from '../text';

const EditWorkExperience = forwardRef((_, ref) => {
    const user = useContext(userContext);
    const [form] = Form.useForm();
    const formInitialValues = {
        company: user.user.experience?.company,
        position: user.user.experience?.position,
        salary: user.user.experience?.salary
    }
    const initialFields = [
        {
            name: ['company'],
            value: user.user.experience?.company
        },
        {
            name: ['position'],
            value: user.user.experience?.position
        },
        {
            name: ['salary'],
            value: user.user.experience?.salary
        },
    ];

    // States
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalAlerts, setModalAlerts] = useState([]);
    const [fields, setFields] = useState(initialFields);

    // Actions
    useImperativeHandle(ref, () => ({
        showModal() {
            setIsModalVisible(true);
        }
    }))

    const handleModalClose = () => {
        setIsModalVisible(false);
        setFields(initialFields);
        setModalAlerts([]);
    }

    const handleErrorAlerts = () => {
        setModalAlerts([
            ...modalAlerts,
            {
                type: 'error',
                content: <Text id='modal::edit-work-experience::message::error' />
            }
        ]);
    };

    const handleModalSubmit = () => {
        form
            .validateFields()
            .then(async values => {
                try {
                    const response = await fetch(APIs.EDIT_WORK_EXPERIENCE, {
                        method: 'POST', body: JSON.stringify({
                            company: values.company.trim(),
                            position: values.position.trim(),
                            salary: values.salary.trim(),
                            user: user.user.id,
                            token: user.user.deviceIdentity
                        })
                    });
                    await response.json();
                    if (response.ok) {
                        const userBackup = { ...user.user };
                        userBackup['experience'] = {};
                        userBackup['experience']['company'] = values.company.trim();
                        userBackup['experience']['position'] = values.position.trim();
                        userBackup['experience']['salary'] = values.salary.trim();
                        user.updateUser(userBackup);
                        initialFields[0].value = values.company.trim();
                        initialFields[1].value = values.position.trim();
                        initialFields[2].value = values.salary.trim();
                        handleModalClose();
                    } else {
                        handleErrorAlerts();
                    }
                } catch (error) {
                    handleErrorAlerts();
                }
            })
    }

    return (
        <ModalWithAlert
            title={<Text id='modal::edit-work-experience::title::edit-work-experience' />}
            visible={isModalVisible}
            onCancel={handleModalClose}
            alerts={modalAlerts}
            footer={[
                <Button key='submit' onClick={handleModalSubmit}>
                    <Text id='modal::edit-work-experience::button::save' />
                </Button>
            ]}>
            <div className='text-gray mt-3'>
                <Form
                    form={form}
                    fields={fields}
                    initialValues={formInitialValues}
                    onFieldsChange={(_, newFields) => {
                        setFields(newFields);
                    }}
                    layout='vertical'>
                    <Form.Item
                        name='company'
                        label={<Text id='modal::edit-work-experience::text::company' />}
                        rules={[
                            { required: true, message: <Text id='modal::edit-work-experience::error::company-required' /> }
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='position'
                        label={<Text id='modal::edit-work-experience::text::position' />}
                        rules={[
                            { required: true, message: <Text id='modal::edit-work-experience::error::position-required' /> }
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='salary'
                        label={<Text id='modal::edit-work-experience::text::salary' />}>
                        <Input />
                    </Form.Item>
                </Form>
            </div>
        </ModalWithAlert>
    );
})

export default EditWorkExperience;
