import React, { useState, forwardRef, useImperativeHandle, useContext } from 'react';
import { userContext } from '../../utils/user';
import { APIs } from '../../utils/constants';
import { DatePicker, Form, Input, Select } from 'antd';
import ModalWithAlert from './modal';
import Button from '../button';
import Text from '../text';
import PropTypes from 'prop-types';
import './edit-interview.scss';
import moment from 'moment';

const EditInterview = forwardRef((props, ref) => {
    const user = useContext(userContext);
    const [form] = Form.useForm();
    const initialFields = [
        {
            name: ['job_title'],
            value: null
        },
        {
            name: ['company'],
            value: null
        },
        {
            name: ['interview_time'],
            value: null
        },
        {
            name: ['interviewer'],
            value: null
        },
        {
            name: ['location'],
            value: null
        },
        {
            name: ['stage'],
            value: 'Applied'
        },
        {
            name: ['job_link'],
            value: null
        },
        {
            name: ['notes'],
            value: null
        },
    ];

    // States
    const [interviewId, setInterviewId] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalAlerts, setModalAlerts] = useState([]);
    const [modalTitle, setModalTitle] = useState(null);
    const [fields, setFields] = useState([]);

    // Actions
    useImperativeHandle(ref, () => ({
        showModal(data) {
            if (data) {
                setModalTitle(<Text id='modal::edit-interview::title::edit-interview' />);
                setFields([
                    {
                        name: ['job_title'],
                        value: data['job-title']
                    },
                    {
                        name: ['company'],
                        value: data['company']
                    },
                    {
                        name: ['interview_time'],
                        value: moment(data['date'] + ' ' + data['time'])
                    },
                    {
                        name: ['interviewer'],
                        value: data['interviewer']
                    },
                    {
                        name: ['location'],
                        value: data['location']
                    },
                    {
                        name: ['stage'],
                        value: data['stage']
                    },
                    {
                        name: ['job_link'],
                        value: data['link']
                    },
                    {
                        name: ['notes'],
                        value: data['description']
                    },
                ]);
                setInterviewId(data.id);
            } else {
                setModalTitle(<Text id='modal::edit-interview::title::add-interview' />);
                setFields(initialFields);
            }
            setIsModalVisible(true);
        }
    }))

    const handleModalClose = () => {
        setIsModalVisible(false);
        setFields(initialFields);
        setModalAlerts([]);
    }

    const handleErrorAlerts = () => {
        setModalAlerts([
            ...modalAlerts,
            {
                type: 'error',
                content: interviewId ?
                    <Text id='modal::edit-interview::message::error::update' /> :
                    <Text id='modal::edit-interview::message::error::save' />
            }
        ]);
    };

    const handleModalSubmit = () => {
        form
            .validateFields()
            .then(async values => {
                try {
                    const response = await fetch(APIs.ADD_INTERVIEW, {
                        method: 'POST', body: JSON.stringify({
                            user: user.user.id,
                            token: user.user.deviceIdentity,
                            jobTitle: values.job_title,
                            company: values.company,
                            date: values.interview_time ? values.interview_time.format('YYYY-MM-DD') : null,
                            time: values.interview_time ? values.interview_time.format('HH:mm') : null,
                            location: values.location,
                            stage: values.stage,
                            link: values.job_link,
                            notes: values.notes,
                            interview: interviewId
                        })
                    });
                    const r = await response.json();
                    if (response.ok) {
                        props.addInterview(r);
                        handleModalClose();
                    } else {
                        handleErrorAlerts();
                    }
                } catch (error) {
                    handleErrorAlerts();
                }
            })
    }

    return (
        <ModalWithAlert
            title={modalTitle}
            visible={isModalVisible}
            onCancel={handleModalClose}
            alerts={modalAlerts}
            footer={[
                <Button key='submit' onClick={handleModalSubmit}>
                    <Text id='modal::edit-interview::button::save' />
                </Button>
            ]}>
            <div className='text-gray mt-3'>
                <Form
                    form={form}
                    fields={fields}
                    onFieldsChange={(_, newFields) => {
                        setFields(newFields);
                    }}
                    layout='vertical'>
                    <Form.Item
                        name='job_title'
                        label={<Text id='modal::edit-interview::text::job-title' />}
                        rules={[
                            { required: true, message: <Text id='modal::edit-interview::error::job-title-required' /> }
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='company'
                        label={<Text id='modal::edit-interview::text::company' />}
                        rules={[
                            { required: true, message: <Text id='modal::edit-interview::error::company-required' /> }
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='interview_time'
                        label={<Text id='modal::edit-interview::text::interview-time' />}
                        rules={[
                            { required: true, message: <Text id='modal::edit-interview::error::interview-time-required' /> }
                        ]}>
                        <DatePicker
                            className='w-100'
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm"
                        />
                    </Form.Item>
                    <Form.Item
                        name='interviewer'
                        label={<Text id='modal::edit-interview::text::interviewer' />}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='location'
                        label={<Text id='modal::edit-interview::text::location' />}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='stage'
                        label={<Text id='modal::edit-interview::text::stage' />}>
                        <Select className='edit-interview'>
                            <Select.Option value="Applied">Applied</Select.Option>
                            <Select.Option value="Interview - HeadHunter">Interview - HeadHunter</Select.Option>
                            <Select.Option value="Interview - HR">Interview - HR</Select.Option>
                            <Select.Option value="Interview - Senior Management Team">Interview - Senior Management Team</Select.Option>
                            <Select.Option value="Offer">Offer</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='job_link'
                        label={<Text id='modal::edit-interview::text::job-link' />}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='notes'
                        label={<Text id='modal::edit-interview::text::notes' />}>
                        <Input.TextArea rows={5} />
                    </Form.Item>
                </Form>
            </div>
        </ModalWithAlert>
    );
})

export default EditInterview;
EditInterview.propTypes = {
    addInterview: PropTypes.func
}