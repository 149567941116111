import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';
import Text from './text';
import JobsterWhiteIcon from '../assets/images/jobster-icon-white.png';
import JobsterWhiteText from '../assets/images/jobster-text-white.png';
import KeLogo from '../assets/images/ke-logo.png';
import { Pages, ExternalLink } from '../utils/constants';

function Footer() {
    return (
        <footer>
            <Row className='bg-blue py-3 py-lg-5 px-4 w-100' gutter={[0, 24]}>
                <Col xs={24} lg={{span: 6, offset: 2}}>
                    <NavLink to={Pages.HOME}>
                        <img src={JobsterWhiteIcon} width='30'></img>
                        <img src={JobsterWhiteText} height='30' className='ml-2 mt-1'></img>
                    </NavLink>
                    <Link to={{ pathname: ExternalLink.KE_HOME }} target='_blank' rel='noopener noreferrer' className='d-none d-lg-inline'>
                        <img src={KeLogo} height='30' className='ml-3'></img>
                    </Link>
                    <div className='mt-3 d-none d-lg-block'>
                        <small><Text id='footer::text::copy-right' /></small>
                    </div>
                </Col>
                <Col xs={12} lg={{span: 4, offset: 2}}>
                    <Typography.Paragraph className='mb-2'>
                        <NavLink to={Pages.ABOUT_US} className='text-white'>
                            <Text id='footer::link::about-jobster' />
                        </NavLink>
                    </Typography.Paragraph>
                    <Typography.Paragraph className='mb-2'>
                        <NavLink to={Pages.PRIVACY_POLICY} className='text-white'>
                            <Text id='footer::link::privacy-policy' />
                        </NavLink>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        <NavLink to={Pages.TERMS_OF_USE} className='text-white'>
                            <Text id='footer::link::terms-of-use' />
                        </NavLink>
                    </Typography.Paragraph>
                </Col>
                <Col xs={12} lg={4}>
                    <Typography.Paragraph className='mb-2'>
                        <Link to={{ pathname: ExternalLink.KE_HOME }} target='_blank' rel='noopener noreferrer' className='text-white'>
                            <Text id='footer::link::ke-solutions' />
                        </Link>
                    </Typography.Paragraph>
                    <Typography.Paragraph className='mb-2 '>
                        <Link to={{ pathname: ExternalLink.FACEBOOK }} target='_blank' rel='noopener noreferrer' className='text-white'>
                            <Text id='footer::link::facebook' />
                        </Link>
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        <Link to={{ pathname: ExternalLink.LINKEDIN }} target='_blank' rel='noopener noreferrer' className='text-white'>
                            <Text id='footer::link::linkedin' />
                        </Link>
                    </Typography.Paragraph>
                </Col>
                <Col xs={24} lg={0}>
                    <small><Text id='footer::text::copy-right' /></small>
                </Col>
            </Row>
        </footer>
    );
}
export default Footer;
