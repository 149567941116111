import React, { useRef } from 'react';
import Ebookimg from '../assets/images/ebook-img.png';
import Text from '../components/text';
import Button from '../components/button';
import DownloadEbook from '../components/modal/download-ebook';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

function Ebook() {
	// Modal
	const downloadEbookModal = useRef();

	return (
		<>
			<Helmet>
				<title>
					{`${useIntl().formatMessage({ id: 'ebook::text::title-name' })}`}
				</title>
			</Helmet>
			<div className='my-2 my-lg-5 justify-content-center'>
				<div className='col-lg-10 m-auto'>
					<div className='row'>
						<div className='col-lg-4 m-auto'>
							<img className='w-100' src={Ebookimg} alt='' />
						</div>
						<div className='col-lg-8'>
							<h1 className='text-blue font-weight-bold font-size-normal'>
								<Text id='ebook::text::title' />
							</h1>
							<div className='mt-5 font-size-smaller font-weight-light'>
								<p className='font-size-smaller'>
									<Text id='ebook::text::desc1' />
								</p>
								<p className='font-size-smaller'>
									<Text id='ebook::text::desc2' />
								</p>
								<ul className='font-size-smaller'>
									<li>
										<Text id='ebook::text::li1' />
									</li>
									<li>
										<Text id='ebook::text::li2' />
									</li>
									<li>
										<Text id='ebook::text::li3' />
									</li>
									<li>
										<Text id='ebook::text::li4' />
									</li>
								</ul>
								<p className='font-size-smaller'>
									<Text id='ebook::text::desc3' />
								</p>
							</div>
							<Button size='lg' onClick={() => downloadEbookModal.current.showModal()}>
								<Text id='ebook::button::download-free-ebook' />
							</Button>
						</div>
						<DownloadEbook ref={downloadEbookModal} />
					</div>
				</div>
			</div>
		</>
	);
}

export default Ebook;
