import React, { useState, forwardRef, useImperativeHandle, useContext } from 'react';
import { userContext } from '../../utils/user';
import { APIs, PhoneNumberPattern } from '../../utils/constants';
import { Form, InputNumber } from 'antd';
import ModalWithAlert from './modal';
import Button from '../button';
import Text from '../text';

const EditPhone = forwardRef((_, ref) => {
    const user = useContext(userContext);
    const [form] = Form.useForm();
    const formInitialValues = {
        phone: user.user.phone
    }
    const initialFields = [
        {
            name: ['phone'],
            value: user.user.phone
        }
    ];

    // States
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalAlerts, setModalAlerts] = useState([]);
    const [fields, setFields] = useState(initialFields);

    // Actions
    useImperativeHandle(ref, () => ({
        showModal() {
            setIsModalVisible(true);
        }
    }))

    const handleModalClose = () => {
        setIsModalVisible(false);
        setFields(initialFields);
    }

    const handleErrorAlerts = () => {
        setModalAlerts([
            ...modalAlerts,
            {
                type: 'error',
                content: <Text id='modal::edit-phone::message::error' />
            }
        ]);
    };

    const handleModalSubmit = () => {
        form
            .validateFields()
            .then(async values => {
                try {
                    const response = await fetch(APIs.EDIT_PHONE, {
                        method: 'POST', body: JSON.stringify({
                            phone: values.phone,
                            user: user.user.id,
                            token: user.user.deviceIdentity
                        })
                    });
                    if (response.ok) {
                        const userBackup = { ...user.user };
                        userBackup['phone'] = values.phone;
                        user.updateUser(userBackup);
                        initialFields[0].value = values.phone;
                        handleModalClose();
                    } else {
                        handleErrorAlerts();
                    }
                } catch (error) {
                    handleErrorAlerts();
                }
            })
    }

    return (
        <ModalWithAlert
            title={<Text id='modal::edit-phone::title::edit-phone' />}
            visible={isModalVisible}
            onCancel={handleModalClose}
            alerts={modalAlerts}
            footer={[
                <Button key='submit' onClick={handleModalSubmit}>
                    <Text id='modal::edit-phone::button::save' />
                </Button>
            ]}>
            <div className='text-gray mt-3'>
                <Form
                    form={form}
                    fields={fields}
                    initialValues={formInitialValues}
                    onFieldsChange={(_, newFields) => {
                        setFields(newFields);
                    }}
                    layout='vertical'>
                    <Form.Item
                        name='phone'
                        label={<Text id='modal::edit-phone::text::phone' />}
                        rules={[
                            { required: true, message: <Text id='modal::edit-phone::error::phone-required' /> },
                            { pattern: PhoneNumberPattern, message: <Text id='modal::edit-phone::error::phone-invalid' /> }
                        ]}>
                        <InputNumber className='w-100 border-0' />
                    </Form.Item>
                </Form>
            </div>
        </ModalWithAlert>
    );
})

export default EditPhone;
