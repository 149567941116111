import React from 'react';
import { Row, Col, Typography} from 'antd';

function JDOverview() {

    return (
        <React.Fragment>
            <Row className='mb-5'>
                <Col span={20} offset={2} className='text-center'>
                    <Typography.Title>
                        Thanks for uploading your Job Description! <br />Could you confirm the following information extracted from your Job Description?
                    </Typography.Title>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default JDOverview;