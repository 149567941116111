import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl'
function PrivacyPolicy() {
    return (
        <>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'privacy-policy::text::title-name' })}`}
                </title>
            </Helmet>
            <div className='row my-5'>
                <div className='col-8 offset-2'>
                    <h1>Privacy Policy</h1>
                    <p><i>Last updated on December 2020</i></p>
                    <p>At Jobster, protecting your privacy is very important to us. We treat your personal information with the utmost respect and in accordance with this Privacy Policy. This Privacy Policy explains how personal information about you may be collected, used, or disclosed as a consequence of your activities with Jobster, our affiliates and third parties who provide services on our behalf. Our service providers are given the information they need to perform their designated functions, and we do not authorize them to use or disclose personal information for their own marketing or other purposes.</p>
                    <p>In the course of its commercial activities, Jobster ordinarily collects, uses and discloses personal information to facilitate connecting job seekers and employers. Jobster may use online services to help connect job seekers and employers, and may disclose information that identifies its candidates and employees to such service providers for this purpose.</p>
                    <ul>
                        <li>Protection of Personal Information</li>
                        <li>What Information Do We Collect</li>
                        <li>Use of Information by Jobster</li>
                        <li>Retention of Information</li>
                        <li>Your Consent</li>
                        <li>Disclosure of Information</li>
                        <li>Safeguards</li>
                    </ul>

                    <h3>Protection of Personal Information</h3>
                    <ol>
                        <li>Canadian law governs the collection, use and disclosure of personal information in a manner that recognizes individuals&apos; right of privacy with respect to their personal information.</li>
                        <li>The federal Personal Information Protection and Electronic Documents Act (&quot;PIPEDA&quot;) sets out rules and principles for the management of personal information by commercial organizations. Jobster services are made available to employers and job seekers across Canada. PIPEDA applies to Jobster because it is an organization headquartered in Ontario, Canada.</li>
                        <li>The type of personal information that is protected includes almost any information about an identifiable individual, such as your name, address, telephone number, age, sex, marital status and nationality. Personal information may not, however, include certain types of business or employment related information. It does not, for example, include the name, title, business address or telephone number of an employee of an organization. Information contained in Resumes that you elect to post to this site may constitute personal information and would be subject to this Privacy Policy.</li>
                        <li>PIPEDA provides certain fundamental principles which apply to the collection, use and disclosure of your personal information. One of the most significant is that your personal information generally cannot be collected, used or disclosed without your consent. It also allows you to find out why your personal information is being collected, how it is being used, where it&apos;s being stored and to whom it&apos;s disclosed. It requires the use of appropriate safeguards to secure your personal information. It also grants you access to your personal information and provides mechanisms for ensuring compliance.</li>
                        <li>Jobster does not collect, use or disclose your personal information without your consent except where required or allowed by law.</li>
                        <li>Jobster limits the collection of your personal information to that which is required or permitted by law. Jobster collects such personal information as is required for the purposes identified by us in this Privacy Policy or identified by us at the time that we collect the information from you.</li>
                        <li>Jobster requests certain information from you that includes all information listed in the client registration form and information that you upload, including your resume.</li>
                        <li>If you are a registered client of the site you may elect to post your resume to the site. The extent of the information that is collected when you post a Resume depends on what you choose to place in your Resume. At a minimum, if you post a Resume, Jobster will require that you provide your full name, postal code, phone number and email address. Your Resume may contain additional information such as your experience, education, skills or any other information about yourself that you decide to provide.</li>
                        <li>At times, we may ask you to provide us with general information about yourself to assist us in meeting your various needs or to better understand the requirements, profiles and experience of our site visitors. We may also ask you to provide or allow us access to specific information about yourself contained on other databases. This information includes information that is collected if you choose to participate in a survey, if you ask to be kept updated about new developments, services or features offered by Jobster or its affiliates, if you utilize any of our services or features, or if you contact Jobster. All of the information in this paragraph is referred to in this Privacy Policy as &quot;User Information&quot;.</li>
                        <li>You can visit this site without telling us who you are and without providing Jobster with any information about yourself. However, certain anonymous information (&quot;Anonymous Information&quot;) may be collected by us automatically. Our Web server may collect the IP address and the name of the Internet service provider (&quot;ISP&quot;) associated with the computer from which you accessed this site, the type and version of Web browser and operating system that you are using, and the address of the Web site that you visited prior to this site. If your Web browser is configured to accept cookies, we may place either temporary cookies (only until you exit your current browser window) or permanent cookies (until you manually disable or delete the cookie using your browser software) on your computer. A &quot;cookie&quot; is a small piece of information that our Web server automatically sends to your browser for storage on your computer, which then permits our Web server to recall information about your preferences as a part of the Anonymous Information. Please note that portions of this site will not function if you do not accept cookies.</li>
                        <li>With the exception of the Anonymous Information described above, you will always know when we are collecting information from you and therefore have the opportunity not to provide the information.</li>
                        <li>CThe purposes for which we collect any information will always be as set out in this Privacy Policy or as otherwise expressly communicated to you before we collect the information.</li>
                        <li>We do not knowingly solicit information from children and we do not knowingly market our services to minors.</li>
                    </ol>

                    <h3>What Information do we Collect</h3>
                    <h3>Registration Information</h3>
                    <p>Registration Information is collected primarily for the purpose of identifying you as a user of Jobster and its affiliates.</p>
                    <h3>User Information</h3>
                    <ol>
                        <li>User Information, which generally includes the Registration Information, is used primarily to provide you with services that you have requested.</li>
                        <li>User Information may also be used to determine appropriate new features, content or services for this site, to permit Jobster to contact you in a more specific and focused manner depending the content of your User Information, or to provide business partners with aggregate but not personally identifiable information about our customers and their preferences.</li>
                        <li>If you are a registered client of Jobster and have provided us with Registration Information, we may use your Registration Information and User Information to provide tailored job opportunities or other information to you.</li>
                    </ol>
                    <h3>Anonymous Information</h3>
                    <ol>
                        <li>Anonymous Information is used primarily by us and our business partners to measure the number of visits, average time spent, page views, and other statistics about visitors to this site. We may also use this data to monitor site performance for systems administration purposes, to make this site easier and more convenient to use, for law enforcement purposes, and to report information in aggregate but not personally identifiable form to our advertisers (e.g. how many visitors log in to this site).</li>
                        <li>If cookies are enabled for your Internet browser, then they are used to help us measure the number of visits, average time spent, page views, and other statistics relating to your use of this site. If you are performing a search on our site, then a cookie is used to carry the search request data from the request page to the results page. This cookie, by itself, does not tell us your email address or who you are.</li>
                        <li>If we use your Registration Information and User Information to provide tailored job opportunities or other information to you, we use cookies to recognize you on subsequent visits to our site and to make your on-line experience more convenient.</li>
                    </ol>
                    <h3>Resumes</h3>
                    <ol>
                        <li>Resumes are accessed by career consultants, engagement managers, and business partners and other registered clients of the Jobster Resume database.</li>
                        <li>Jobster makes every effort through contractual means and through the use of user IDs and passwords to restrict those who have access to our Resume database from using Resumes other than for the purpose of fulfilling a job posting. However, Jobster cannot guarantee that all career consultants, engagement managers, and other persons with access will adhere to the limitations that we impose on them, or that unauthorized persons will not gain access to the database. If at any time you would like your Resume removed from our database, you may do so by contacting us by phone or by email to info@kesolutions.ca. However, career consultants, engagement managers and other business partners who have been granted access to the resume database or for whose job posting you may have applied, may already have retained a copy of your Resume in their own systems and databases.</li>
                        <li>Some job postings which are posted on this site allow you to follow links to other sites where you can apply for specific job postings or submit your Resume. In many cases these sites are co-branded with the sites of our employer or human resources customers or with the sites of our business partners. In some cases, such sites are still within the control of the Jobster Web server. Any resume or other information of any kind that you provide, including as a part of a job application process, is outside the control of Jobster and its Web servers. In such cases, you will be providing your resume directly to the career consultants, engagement managers or business partners, and your resume will be subject to their practices and their privacy policies or practices. Jobster is not responsible for the privacy policies or practices of such third parties. In such cases, we encourage you to review the third party&apos;s privacy policy.</li>
                        <li>In addition to the provisions of this Privacy Policy, the collection, use and disclosure of your Resume is governed by the terms and conditions of use of this site, a copy of which can be obtained from the home page of this site.</li>
                    </ol>

                    <h3>Communication with Jobster</h3>
                    <p>If you contact us, we may keep a record of the correspondence but we do not make any use of the email address or other contact information, other than to respond to the communication.</p>

                    <h3>Use of Information by Jobster</h3>
                    <h3>Improper Use of Personal Information</h3>
                    <p>Although we use all reasonable means to ensure that the information you provide to us is not used by third parties for purposes other than those described in this Privacy Policy, Jobster is not responsible for any improper use of your personal information that is beyond our reasonable control.</p>

                    <h3>Retention of Information</h3>
                    <ol>
                        <li>You may choose to delete your Resume from our records at any time. We also delete Resumes from our records in accordance with internally developed experience criteria based upon when your Resume was created, last edited or last used to apply for a job posting, as well as your use of your account. Certain Registration Information regarding your account with us will normally be retained such as your name, email address and postal code should you wish to post a Resume or access our services subsequently.</li>
                        <li>JWe maintain record retention practices and policies which apply to personal information that is no longer necessary or relevant for the identified purposes, or that is no longer required or permitted by law to be retained.</li>
                    </ol>

                    <h3>Your Consent</h3>
                    <p>We do not collect, use or disclose your personal information without your consent except where required or permitted by law.</p>
                    <p>We may, for example, collect, use or disclose personal information without your knowledge or consent in the case of an emergency where the life, health or security of an individual is threatened.</p>
                    <p>We may also disclose personal information without knowledge or consent to a lawyer or other advisor representing Jobster, to collect a debt, to comply with a subpoena, warrant or other court order or rules, is required for the investigation or enforcement of laws, or as may otherwise be required or authorized by law. Where a request for personal information is made by an organization claiming lawful authority for the request, Jobster will take reasonable steps to ensure that the requesting party properly identifies its legal authority.</p>
                    <p>We will generally obtain your consent electronically on our site. However, it may also be obtained in writing or verbally. In determining the appropriate form of consent, we will consider the sensitivity of and reasonable expectations regarding the personal information. We will not require you to consent to the collection, use or disclosure of personal information as a condition to the supply of any products or services, except to the extent reasonably required to fulfill the purposes identified in this Privacy Policy or otherwise identified at the time that we collect your personal information.</p>
                    <p>You may withdraw your consent to the continued collection, use or disclosure of your personal information by Jobster at any time by phone or by email to info@kesolutions.ca, Subject &quot;Privacy Policy&quot; and filling in your request in the contact form, but such a request would be subject to legal or contractual restrictions. We would also require reasonable advance notice to effect any requested changes. A request that we no longer collect or use your registration information or certain User Information or Anonymous Information, may mean that we will no longer have practical means to provide you with our services or products.</p>
                    <p>If you withdraw your consent to the disclosure of personal information that you have previously authorized us to share with others, such as your Resume, you should know that career consultants, engagement managers, business partners and others who have been granted access to the Resume database or for whose job posting you may have applied, may already have retained a copy of your Resume or such personal information in their own systems and databases.</p>

                    <h3>Disclosure of Information</h3>
                    <ol>
                        <li>Jobster uses third party service providers to process certain data, including resumes for resume database search functionality. Third party service providers are required to comply with privacy laws and principles comparable to ours.</li>
                        <li>We may disclose your personal information to third parties who acquire all or a substantial part of the Jobster business, including by merger, amalgamation, a sale of our assets or in the unlikely event of bankruptcy. Subject to a court order, at the time of such merger, amalgamation, sale or bankruptcy, Jobster would contractually require any resulting entity that obtains your personal information to comply with its obligations under PIPEDA regarding your personal information, including the applicable terms and conditions of this Privacy Policy.</li>
                        <li>Where Jobster considers it appropriate, having due regard to the sensitivity of the particular information that we collect, use or disclose, Jobster uses encryption or authentication tools, among other methods, practices and procedures, to protect certain your personal information irrespective of which format it is stored in. Our practices and procedures for safeguarding your information are regularly reviewed.</li>
                        <li>E-mails you send to us are not necessarily secure when they are transmitted to us. If your communication is sensitive or includes confidential information, such as a credit card number, you may want to provide it by post or via the telephone instead.</li>
                        <li>It is important that you keep your User ID and password information secure and that you not share it with anyone, in order to safeguard your personal information on our Site.</li>
                        <li>There are occasionally fraudulent &quot;phishing&quot; emails targeting individuals who the perpetrators believe might be registered clients of this site. We never send an email which requests users to visit a Web page or place a call and provide their user ID, password or any personal information. We will never ask you to confirm your user ID or password via email. If you ever receive such an email, do not log in from any referred link and do not provide any personal information.</li>
                        <li>We work to protect the security of credit card information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input. We do not reveal credit card numbers when confirming an order.</li>
                    </ol>

                    <h3>Safeguards</h3>
                    <ol>
                        <li>Jobster reserves the right to modify or supplement this Privacy Policy statement at any time. If we make any material change to this Privacy Policy, we will update this site to include such changes and post a notice on our home page, for a period of 30 days, with a link to the updated Privacy Policy. Please read any such notice and the new policy statement. If you return to this site after a period of more than 30 days, please check this Privacy Policy to confirm that you are aware of the details of the most recent update. Please look at the top of this Privacy Policy to check the date that it was updated and to confirm that you are familiar with the terms of the most recent update. Your continued use of this site after we post such notice will constitute your acceptance of the new terms and their application to your use of this site and the personal information disclosed for such use, including personal information previously furnished us, as if they were the initial terms, and your consent to the use of your personal information as described in this privacy policy and elsewhere at our Website.</li>
                        <li>We will always seek your consent if we want to use your personal information for purposes other than for those set out in this Privacy Policy or to which you have previously agreed.</li>
                    </ol>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;
