import React, { useState, useEffect, useContext, useRef } from 'react';
import { Alert, Col, Row, Select, Table, Typography, Skeleton } from 'antd';
import { Pencil, Trash } from 'react-bootstrap-icons';
import { userContext } from '../../utils/user';
import { APIs } from '../../utils/constants';
import Text from '../../components/text';
import Button from '../../components/button';
import EditInterview from '../../components/modal/edit-interview';
import moment from 'moment';
import './interview.scss';

function DashboardInterviews() {
    const user = useContext(userContext);
    const columns = [
        {
            title: <Text id='dashboard::interviews::table::header::job-title' />,
            dataIndex: 'job-title',
            key: 'job-title',
            ellipsis: true,
            width: '20%',
            render: (text, col) => {
                if (col.link) {
                    return <a className='text-primary' href={col.link} target='_blank' rel='noopener noreferrer'>
                        {text}
                    </a>
                } else {
                    return text;
                }
            }
        },
        {
            title: <Text id='dashboard::interviews::table::header::company' />,
            dataIndex: 'company',
            key: 'company',
            ellipsis: true,
            width: '20%'
        },
        {
            title: <Text id='dashboard::interviews::table::header::interview-time' />,
            dataIndex: 'interview-time',
            key: 'interview-time',
            width: '15%',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => (a.date + ' ' + a.time).localeCompare(b.date + ' ' + b.time),
            render: (_, col) =>
                <>
                    <Typography.Text className='d-block'>{col.date}</Typography.Text>
                    <Typography.Text>{col.time}</Typography.Text>
                </>
        },
        {
            title: <Text id='dashboard::interviews::table::header::location' />,
            dataIndex: 'location',
            key: 'location',
            width: '15%',
        },
        {
            title: <Text id='dashboard::interviews::table::header::interviewer' />,
            dataIndex: 'interviewer',
            key: 'interviewer',
            width: '10%',
        },
        {
            title: <Text id='dashboard::interviews::table::header::stage' />,
            dataIndex: 'stage',
            key: 'stage',
            width: '10%',
        },
        {
            title: <Text id='dashboard::interviews::table::header::actions' />,
            dataIndex: 'actions',
            key: 'actions',
            width: '10%',
            render: (_, col) =>
                <>
                    <Pencil className='cursor-pointer mr-3' onClick={() => editInterviewModal.current.showModal(col)} />
                    <Trash className='cursor-pointer' onClick={() => deleteInterview(col.id)} />
                </>
        },
    ];

    // Modal
    const editInterviewModal = useRef();

    // States
    const [pastInterviews, setPastInterviews] = useState([]);
    const [currentInterviews, setCurrentInterviews] = useState([]);
    const [error, setError] = useState(false);
    const [loadingInterviews, setLoadingInterviews] = useState(true);
    const [tableColumns, setTableColumns] = useState(columns);
    const [tableData, setTableData] = useState([]);

    // Actions
    const getErrorMessage = <Text id='dashboard::interviews::error::get' />;
    const deleteErrorMessage = <Text id='dashboard::interviews::error::delete' />;

    useEffect(() => {
        const fetchInterviews = async () => {
            setLoadingInterviews(true);

            const loadingColumns = [];
            for (let i = 0; i < 7; i++) {
                const loadingColumn = {
                    key: i,
                    title: <Skeleton.Button active='true' size='small' />,
                    dataIndex: 'data'
                };
                loadingColumns.push(loadingColumn);
            }
            setTableColumns(loadingColumns);

            const loadingDatas = [];
            for (let i = 0; i < 10; i++) {
                const loadingData = { key: i, data: <Skeleton.Button active='true' size='small' className='w-100' /> };
                loadingDatas.push(loadingData);
            }
            setTableData(loadingDatas);

            try {
                const response = await fetch(APIs.GET_INTERVIEWS, {
                    method: 'POST', body: JSON.stringify({
                        user: user.user.id,
                        token: user.user.deviceIdentity
                    })
                });
                if (response.ok) {
                    const r = await response.json();
                    const now = moment();
                    const current = [];
                    const past = [];
                    r.items.forEach(interview => {
                        if (moment(interview.date + ' ' + interview.time).isAfter(now)) {
                            current.push(interview);
                        } else {
                            past.push(interview);
                        }
                    });
                    setPastInterviews(past);
                    setCurrentInterviews(current);
                    setTableColumns(columns);
                    setTableData(current);
                    setLoadingInterviews(false);
                } else {
                    setError(getErrorMessage);
                }
            } catch {
                setError(getErrorMessage);
            }
        };

        fetchInterviews();
    }, []);


    const addInterview = interview => {
        let newInterviews = [];
        let found = false;
        if (moment(interview.date + ' ' + interview.time).isAfter(moment())) {
            currentInterviews.forEach(currentInterview => {
                if (currentInterview.id === interview.id) {
                    found = true;
                    newInterviews.push(interview);
                } else {
                    newInterviews.push(currentInterview);
                }
            });
            if (!found) {
                newInterviews = [interview, ...currentInterviews];
            }
            setCurrentInterviews(newInterviews);
        } else {
            pastInterviews.forEach(pastInterview => {
                if (pastInterview.id === interview.id) {
                    found = true;
                    newInterviews.push(interview);
                } else {
                    newInterviews.push(pastInterview);
                }
            });
            if (!found) {
                newInterviews = [interview, ...pastInterviews];
            }
            setTableData(pastInterviews);
        }
        setTableData(newInterviews);
    }

    const deleteInterview = async interviewId => {
        try {
            const response = await fetch(APIs.DELETE_INTERVIEW, {
                method: 'POST', body: JSON.stringify({
                    user: user.user.id,
                    token: user.user.deviceIdentity,
                    interview: interviewId
                })
            });
            if (response.ok) {
                setPastInterviews(prevPastInterviews => {
                    const newPastInterviews = prevPastInterviews.filter(interview => interview.id !== interviewId);
                    return [...newPastInterviews];
                })

                setCurrentInterviews(prevCurrentInterviews => {
                    const newCurrentInterviews = prevCurrentInterviews.filter(interview => interview.id !== interviewId);
                    return [...newCurrentInterviews];
                })

                setTableData(prevTableData => {
                    const newTableData = prevTableData.filter(interview => interview.id !== interviewId);
                    return newTableData;
                })
            } else {
                setError(deleteErrorMessage);
            }
        } catch (error) {
            setError(deleteErrorMessage);
        }
    };

    return (
        <div className='col-10 offset-1 mt-5 pt-5'>
            {error &&
                <Alert
                    className='position-absolute w-100 text-danger text-center'
                    style={{ left: 0, top: 0 }}
                    message={error}
                    type='error'
                    showIcon={false}
                    banner
                />
            }
            <Row className='mb-4'>
                <Col span={12}>
                    <Select
                        disabled={loadingInterviews}
                        defaultValue='current-interviews'
                        className='border-bottom w-auto'
                        onChange={(value) => {
                            if (value === 'current-interviews') {
                                setTableData(currentInterviews);
                            } else {
                                setTableData(pastInterviews);
                            }
                        }}>
                        <Select.Option value='current-interviews'
                        >
                            <Typography.Title level={4}>
                                <Text id='dashboard::interviews::select::current-interviews' />
                            </Typography.Title>
                        </Select.Option>
                        <Select.Option value='past-interviews'>
                            <Typography.Title level={4}>
                                <Text id='dashboard::interviews::select::past-interviews' />
                            </Typography.Title>
                        </Select.Option>
                    </Select>
                </Col>
                <Col span={12} className='text-right'>
                    <Button size='sm' onClick={() => editInterviewModal.current.showModal()}>
                        <Text id='dashboard::interviews::button::add' />
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        dataSource={tableData}
                        columns={tableColumns}
                        pagination={{ pageSize: 15, className: 'mt-4 text-center', showSizeChanger: false, total: tableData.length ? tableData.length : 1 }}
                        expandable={{
                            expandedRowRender: col => <Typography.Paragraph>
                                {col.description}
                            </Typography.Paragraph>,
                            rowExpandable: col => col.description ? true : false,
                        }}
                    />
                </Col>
            </Row>
            <EditInterview ref={editInterviewModal} addInterview={addInterview} />
        </div>
    );
}

export default DashboardInterviews;
