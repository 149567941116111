import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Tabs, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import Button from '../button.js';
import Text from '../text.js';
import { APIs, EmailPattern } from '../../utils/constants.js';
import ModalWithAlert from './modal.js';
import './login.scss';
const Login = forwardRef((props, ref) => {
    let userEmail;
    // Constants
    const LOGIN = 'login';
    const SIGNUP = 'signup';
    const FORGOT_PASSWORD = 'forgot-password';
    const { TabPane } = Tabs;
    const [form] = Form.useForm();

    // States
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalAlerts, setModalAlerts] = useState([]);
    const [modalAlertsOption, setModalAlertsOption] = useState({});
    const [modalPage, setModalPage] = useState(LOGIN);
    const [loginFields, setLoginFields] = useState([
        {
            name: ['email'],
            value: ''
        },
        {
            name: ['password'],
            value: ''
        }
    ]);
    const [signupFields, setSignupFields] = useState([
        {
            name: ['fullname'],
            value: ''
        },
        {
            name: ['email'],
            value: ''
        },
        {
            name: ['password'],
            value: ''
        }
    ]);
    const [forgotPasswordFields, setForgotPasswordFieldsFields] = useState([
        {
            name: ['email'],
            value: ''
        }
    ]);

    // Actions
    useImperativeHandle(ref, () => ({
        showModal() {
            setIsModalVisible(true);
        }
    }))

    const getErrorMap = () => {
        return {
            'general': {
                type: 'error',
                content: <Text id='message::error::general' />
            },
            'E00004': {
                type: 'error',
                content: <Text id='modal::login::message::error::email-password-invalid' />
            },
            'E00005': {
                type: 'error',
                content: <Text id='modal::login::message::error::email-password-invalid' />
            },
            'E00006': {
                type: 'error',
                content: <Text id='modal::login::message::error::email-not-verified' />,
                actionText: <Text id='modal::login::button::resend' />,
                action: handleResendVerificationEmail
            },
            'E00007': {
                type: 'error',
                content: <Text id='modal::login::message::error::not-send-account-verification-email' values={{
                    email: () => <strong>{userEmail}</strong>
                }} />
            },
            'E00008': {
                type: 'error',
                content: <Text id='modal::login::message::error::email-format-invalid' />
            },
            'E00010': {
                type: 'error',
                content: <Text id='modal::login::message::error::email-registered' />
            },
            'E00011': {
                type: 'error',
                content: <Text id='modal::login::message::error::email-not-exist' />
            },
            'E00012': {
                type: 'error',
                content: <Text id='modal::login::message::error::not-send-reset-password-email' />
            },
        }
    }

    const getSubmitButton = () => {
        switch (modalPage) {
            case LOGIN:
                return <Button onClick={handleSubmit}>
                    <Text id='modal::login::button::login' />
                </Button>
            case SIGNUP:
                return <Button onClick={handleSubmit}>
                    <Text id='modal::login::button::create-account' />
                </Button>
            case FORGOT_PASSWORD:
                return <Button onClick={handleSubmit}>
                    <Text id='modal::login::button::send-link' />
                </Button>
        }
    }


    const handleModalPageChange = e => {
        setModalPage(e);
    }

    const handleModalClose = () => {
        setModalAlerts([]);
        setModalPage(LOGIN);
        setIsModalVisible(false);
    }

    const handleErrorAlerts = (errorCode, currentModalAlerts) => {
        const errorMap = getErrorMap();
        setModalAlerts([
            ...currentModalAlerts,
            errorMap[errorCode] ? errorMap[errorCode] : errorMap['general']
        ]);
    }

    const handleResendVerificationEmail = async (e, alerts) => {
        setModalAlertsOption({
            ...modalAlertsOption,
            disableAllActions: true
        });
        e.target.disabled = true;
        let counter = 60;
        const originalText = e.target.innerText;
        const countDown = () => {
            if (counter > 0) {
                e.target.innerText = originalText + ' ' + counter;
                setTimeout(() => {
                    countDown();
                }, 1000);
                counter--;
            } else {
                e.target.innerText = originalText;
                e.target.disabled = false;
                setModalAlertsOption({
                    ...modalAlertsOption,
                    disableAllActions: false
                });
            }
        }

        countDown();

        try {
            const response = await fetch(APIs.SEND_ACCOUNT_VERIFICATION_EMAIL, {
                method: 'POST', body: JSON.stringify({
                    email: userEmail.trim()
                })
            });
            const r = await response.json();
            if (response.ok) {
                setModalAlerts([
                    ...alerts,
                    {
                        'type': 'info',
                        'content': <Text id='modal::login::message::info::resend_account_verification_email' values={{
                            email: () => <strong>{userEmail}</strong>
                        }} />
                    }
                ]);
            } else {
                handleErrorAlerts(r.error, alerts);
            }
        } catch (error) {
            handleError();
        }
    }

    const handleError = () => {
        // console.log(e);
    }

    const handleSubmit = () => {
        form
            .validateFields()
            .then(async values => {
                const email = values.email.trim();
                userEmail = email;
                if (modalPage === LOGIN) {
                    try {
                        const response = await fetch(APIs.LOGIN, {
                            method: 'POST', body: JSON.stringify({
                                email,
                                password: values.password.trim()
                            })
                        });
                        const r = await response.json();
                        if (response.ok) {
                            localStorage.setItem('user', JSON.stringify(r.user));
                            handleModalClose();
                            props.onLoggedIn();
                        } else {
                            handleErrorAlerts(r.error, modalAlerts);
                        }
                    } catch (error) {
                        handleError();
                    }
                } else if (modalPage === SIGNUP) {
                    try {
                        const response = await fetch(APIs.SIGNUP, {
                            method: 'POST', body: JSON.stringify({
                                fullname: values.fullname.trim(),
                                email,
                                password: values.password.trim()
                            })
                        });
                        const r = await response.json();
                        if (response.ok) {
                            setModalAlerts([
                                ...modalAlerts,
                                {
                                    'type': 'info',
                                    'content': <Text id='modal::login::message::info::verification-email-sent' values={{
                                        email: () => <strong>{userEmail}</strong>
                                    }} />,
                                    actionText: <Text id='modal::login::button::resend' />,
                                    action: handleResendVerificationEmail
                                }
                            ]);
                        } else {
                            handleErrorAlerts(r.error, modalAlerts);
                        }
                    } catch (error) {
                        handleError();
                    }
                } else if (modalPage === FORGOT_PASSWORD) {
                    try {
                        const response = await fetch(APIs.FORGOT_PASSWORD, {
                            method: 'POST', body: JSON.stringify({
                                email
                            })
                        });
                        const r = await response.json();
                        if (response.ok) {
                            setModalAlerts([
                                ...modalAlerts,
                                {
                                    'type': 'info',
                                    'content': <Text id='modal::login::message::info::reset-password-email-sent' values={{
                                        email: () => <strong>{userEmail}</strong>
                                    }} />
                                }
                            ]);
                        } else {
                            handleErrorAlerts(r.error, modalAlerts);
                        }
                    } catch (error) {
                        handleError();
                    }
                }
            })
    }

    // UI
    const emailFormItem = <Form.Item
        name='email'
        label={<Text id='modal::login::text::email' />}
        rules={[
            { required: true, message: <Text id='modal::login::error::email-required' /> },
            { pattern: EmailPattern, message: <Text id='modal::login::error::email-invalid' /> }
        ]}>
        <Input />
    </Form.Item>
    const passwordFormItem = <Form.Item
        name='password'
        label={<Text id='modal::login::text::password' />}
        rules={[
            { required: true, message: <Text id='modal::login::error::password-required' /> }
        ]}>
        <Input.Password />
    </Form.Item>
    const fullnameFormItem = <Form.Item
        name='fullname'
        label={<Text id='modal::login::text::fullname' />}
        rules={[
            { required: true, message: <Text id='modal::login::error::fullname-required' /> }
        ]}>
        <Input className='w-50' />
    </Form.Item>

    return (
        <ModalWithAlert
            title={modalPage === 'forgot-password' ? <Text id='modal::login::text::reset-password' /> : <Tabs defaultActiveKey='login' centered size='large' className='login-nav without-border' onChange={handleModalPageChange}>
                <TabPane tab={<Text id='modal::login::tab::login' />} key={LOGIN} />
                <TabPane tab={<Text id='modal::login::tab::signup' />} key={SIGNUP} />
            </Tabs>}
            visible={isModalVisible}
            onCancel={handleModalClose}
            className={modalPage === 'forgot-password' ? 'login-modal' : 'login-modal login-tab-modal'}
            alerts={modalAlerts}
            alertsOption={modalAlertsOption}
            footer={[
                <div key='submit'>
                    {getSubmitButton()}
                    {modalPage === 'forgot-password' && <div className='text-gray my-3'>
                        <Text id='modal::login::button::back-to-login' values={{
                            link: msg => <a className='text-primary' onClick={() => handleModalPageChange(LOGIN)}>{msg}</a>
                        }} />
                    </div>}
                </div>
            ]}>
            <div className='text-gray mt-3'>
                {modalPage === LOGIN &&
                    <Form
                        form={form}
                        fields={loginFields}
                        onFieldsChange={(_, newFields) => {
                            setLoginFields(newFields);
                        }}
                        layout='vertical'
                        name='login-form'>
                        {emailFormItem}
                        {passwordFormItem}
                        <a className='text-primary' onClick={() => handleModalPageChange(FORGOT_PASSWORD)}>
                            <Text id='modal::login::link::forgot-password' />
                        </a>
                    </Form>}
                {modalPage === SIGNUP &&
                    <Form
                        form={form}
                        fields={signupFields}
                        onFieldsChange={(_, newFields) => {
                            setSignupFields(newFields);
                        }}
                        layout='vertical'
                        name='signup-form'>
                        {fullnameFormItem}
                        {emailFormItem}
                        {passwordFormItem}
                    </Form>}
                {modalPage === FORGOT_PASSWORD && <>
                    <Form
                        form={form}
                        fields={forgotPasswordFields}
                        onFieldsChange={(_, newFields) => {
                            setForgotPasswordFieldsFields(newFields);
                        }}
                        layout='vertical'
                        name='forgot-password-form'>
                        {emailFormItem}
                    </Form>
                </>}
            </div>
        </ModalWithAlert>
    );
})

export default Login;
Login.propTypes = {
    onLoggedIn: PropTypes.func
}