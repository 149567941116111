import React, { useState, useEffect, useContext, useRef } from 'react';
import { Alert, Col, Empty, Pagination, Progress, Row, Skeleton, Typography } from 'antd';
import { Trash } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { userContext } from '../../utils/user';
import { APIs, Pages } from '../../utils/constants';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Button from '../../components/button';
import Text from '../../components/text';
import UploadResume from '../../components/modal/upload-resume';

function DashboardResumes() {
    const history = useHistory();
    const user = useContext(userContext);
    const pageSize = 9;

    // Modal
    const uploadResumeModal = useRef();

    // State
    const [resumes, setResumes] = useState([]);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingResumes, setLoadingResumes] = useState(true);
    const [displayedResumes, setDisplayedResumes] = useState(Array(9).fill(0));

    // CSS
    const styles = {
        progressSkeleton: {
            height: '90px',
            width: '90px'
        }
    }

    // Actions
    const getErrorMessage = <Text id='dashboard::resumes::error::get' />;
    const deleteErrorMessage = <Text id='dashboard::resumes::error::delete' />;

    useEffect(() => {
        const fetchResumes = async () => {
            setLoadingResumes(true);
            try {
                const response = await fetch(APIs.GET_RESUMES, {
                    method: 'POST',
                    body: JSON.stringify({
                        user: user.user.id,
                        token: user.user.deviceIdentity
                    })
                });
                if (response.ok) {
                    const r = await response.json();
                    setResumes(r.items);
                    setDisplayedResumes(r.items.slice(0, pageSize));
                    setLoadingResumes(false);
                } else {
                    setError(getErrorMessage);
                }
            } catch {
                setError(getErrorMessage);
            }
        };

        fetchResumes();
    }, []);

    const updateDisplayResumes = page => {
        setCurrentPage(page);
        setDisplayedResumes([]);
        setTimeout(() => {
            setDisplayedResumes(resumes.slice((page - 1) * pageSize, page * pageSize));
        }, 700);
    }

    const deleteResume = async resumeId => {
        try {
            const response = await fetch(APIs.DELETE_RESUME, {
                method: 'POST', body: JSON.stringify({
                    user: user.user.id,
                    token: user.user.deviceIdentity,
                    resume: resumeId
                })
            });
            if (response.ok) {
                let newResumes = resumes.filter(resume => resume.id !== resumeId);
                setResumes([...newResumes]);
                const newDisplayResumes = displayedResumes.filter(resume => resume.id !== resumeId);
                setDisplayedResumes(newDisplayResumes);
                if (!newDisplayResumes.length) {
                    updateDisplayResumes(currentPage - 1);
                } else {
                    setTimeout(() => {
                        setDisplayedResumes(newResumes.slice((currentPage - 1) * pageSize, currentPage * pageSize));
                    }, 1000);
                }
            } else {
                setError(deleteErrorMessage);
            }
        } catch (error) {
            setError(deleteErrorMessage);
        }
    }

    const resumeCard = resume => <Col className='gutter-row' span={8}>
        <div className='bg-gray px-3 py-2'>
            <Row className='mb-4'>
                <Col span={22}>
                    <Typography.Title level={5}>
                        {loadingResumes ?
                            <Skeleton.Button className='w-75' active='true' /> :
                            resume.name}
                    </Typography.Title>
                    <Typography.Text className='d-block'>
                        {loadingResumes ?
                            <Skeleton.Button className='w-50 mb-1' active='true' size='small' /> :
                            <Text id='dashboard::resumes::text::best-job' values={{
                                job: () => resume.job
                            }} />}
                    </Typography.Text>
                    <Typography.Text>
                        {loadingResumes ?
                            <Skeleton.Button className='w-50' active='true' size='small' /> :
                            resume.upload_time}
                    </Typography.Text>
                </Col>
                <Col span={2} className='text-right'>
                    {loadingResumes ? <Skeleton.Button className='w-100' active='true' size='small' /> :
                        <Trash className='cursor-pointer' onClick={() => deleteResume(resume.id)} />}
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    {loadingResumes ? <Skeleton.Button style={styles.progressSkeleton} active='true' shape='circle' /> :
                        <Progress
                            type='circle'
                            percent={resume.percentage}
                            size={90}
                            format={() => resume.percentage + '%'}
                            strokeColor={resume.percentage > 80 ? '#ADDBB2' : resume.percentage > 60 ? '#FFD191' : '#FAAAA5'}
                        />}
                </Col>
                <Col span={12}>
                    {loadingResumes ? <Skeleton.Button className='w-100 position-absolute bottom-0 right-0' active='true' size='small' /> :
                        <Button
                            className='position-absolute bottom-0 right-0'
                            size='small'
                            onClick={() => history.push(Pages.RESULT + resume.uuid)}>
                            <Text id='dashboard::resumes::button::match' />
                        </Button>}
                </Col>
            </Row>
        </div>
    </Col>

    return (
        <div className='col-10 offset-1 mt-5 pt-5 position-relative'>
            {error &&
                <Alert
                    className='position-absolute w-100 text-danger text-center'
                    style={{ left: 0, top: 0 }}
                    message={error}
                    type='error'
                    showIcon={false}
                    banner
                />
            }
            <Row>
                <Col span={12}>
                    <Typography.Title level={3}>
                        <Text id='dashboard::resumes::text::title' />
                    </Typography.Title>
                </Col>
                <Col span={12} className='text-right'>
                    <Button size='sm' onClick={() => uploadResumeModal.current.showModal()}>
                        <Text id='dashboard::resumes::button::add' />
                    </Button>
                </Col>
            </Row>
            <Row gutter={[20, 22]}>
                <TransitionGroup component={null}>
                    {displayedResumes.map((resume, i) =>
                        <CSSTransition timeout={700} classNames='fade-out' key={i} unmountOnExit>
                            {resumeCard(resume)}
                        </CSSTransition>
                    )}
                </TransitionGroup>
                {displayedResumes.length == 0 && !loadingResumes &&
                    <Col className='m-auto' span={24}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Col>
                }
            </Row>
            {resumes.length > 0 && <div className='text-center my-4'>
                <Pagination size='small' current={currentPage} total={resumes.length} pageSize={pageSize} showSizeChanger={false} onChange={page => updateDisplayResumes(page)} />
            </div>}
            <UploadResume ref={uploadResumeModal} />
        </div >
    );
}

export default DashboardResumes;