import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import { systemLanguage, systemMessages } from './locale/index';
import { HelmetProvider } from 'react-helmet-async';
import { ConfigProvider } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const container = document.getElementById('root');

if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <IntlProvider locale={systemLanguage} messages={systemMessages}>
          <ConfigProvider theme={{ hashed: false }}>
            <App />
          </ConfigProvider>
        </IntlProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
} else {
  // console.error('Failed to find the root element');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
