import React from 'react';
import PropTypes from 'prop-types';

Button.propTypes = {
    children: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    solid: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.string,
    className: PropTypes.string
}

function Button({ children, disabled = false, solid = true, onClick, size = 'md', className }) {
    const classNames = require('classnames');
    const btnClass = classNames({
        'jobster-btn': true,
        'jobster-btn-blue': !disabled && solid,
        'jobster-btn-white': !disabled && !solid,
        'jobster-btn-sm': size === 'sm',
        'jobster-btn-md': size === 'md',
        'jobster-btn-lg': size === 'lg'
    }, className);
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={btnClass}>{children}</button >
    );
}

export default Button;
