import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Pages } from '../utils/constants';
import { getUser } from '../utils/auth';

GuardedRoute.propTypes = {
    component: PropTypes.func.isRequired,
}

function GuardedRoute({ component: Component, ...rest }) {
    const user = getUser();
    return <Route {...rest} render={(props) => (
        user ?
            <Component {...props} /> :
            <Redirect to={Pages.HOME} />
    )} />
}

export default GuardedRoute;