import React, { useRef } from 'react';
import { ArrowRight } from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';
import { Pages } from '../utils/constants';
import { excessMax } from '../utils/window';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import Text from '../components/text';
import Button from '../components/button';
import UploadResume from '../components/modal/upload-resume';
import background from '../assets/images/home-selection-background.png';
import jobSeeker from '../assets/images/home-job-seeker.png';
import jobSeekerBanner from '../assets/images/home-job-seeker-banner.png';
import jobSeekerSmall from '../assets/images/home-job-seeker-icon-small.png';
import jobSeekerIcon1 from '../assets/images/home-job-seeker-upload.png';
import jobSeekerIcon2 from '../assets/images/home-job-seeker-star.png';
import jobSeekerIcon3 from '../assets/images/home-job-seeker-suprise.png';
import recruiter from '../assets/images/home-recruiter.png';
import recruiterBanner from '../assets/images/home-recruiter-banner.png';
import recruiterSmall from '../assets/images/home-recruiter-icon-small.png';
import recruiterIcon1 from '../assets/images/home-recruiter-person.png';
import recruiterIcon2 from '../assets/images/home-recruiter-chart.png';
import recruiterIcon3 from '../assets/images/home-recruiter-bulb.png';
import JobsterBlueIcon from '../assets/images/jobster-icon-blue.png';
import RequestDemo from '../components/modal/request-demo';

function Home() {
    const classNames = require('classnames');

    // Modal
    const uploadResumeModal = useRef();
    const requestDemoModal = useRef();

    // Class
    const basicRowClass = {
        'pb-5': true,
        'max-width-2400': true,
        'm-auto': excessMax()
    };
    const desktopRowClass = classNames('d-none', 'd-lg-block', basicRowClass);
    const mobileRowClass = classNames('d-lg-none', basicRowClass);

    // CSS
    const selectionText = {
        marginTop: '8vw',
        fontSize: '2.4vw'
    }
    const mobileSectionText = {
        top: '10px',
        left: '4px'
    }
    const underlineLightGrey = {
        textDecoration: 'underline lightgray'
    }
    const marginLeft5vw = {
        marginLeft: '5vw'
    }

    //UI
    const homeTitleText = <div className='col-12 my-5 text-center'>
        <h1 className='font-weight-lighter d-inline font-size-normal'>
            <Text id='home::text::title' values={{
                bold: msg => <span className='font-weight-bold text-blue font-size-big'>{msg}</span>
            }} />
        </h1>
    </div>

    const homeMiddleText = <div className='col-12 col-lg-8 offset-lg-2 my-3 my-lb-5 text-center'>
        <h1 className='font-weight-lighter font-size-small'>
            <img src={JobsterBlueIcon} width='27' className='mr-2 d-lg-none'></img>
            <Text id='home::text::subtitle' values={{
                bold: msg => <span className='font-weight-bold'>{msg}</span>
            }} />
        </h1>
    </div>

    const homeJobSeekerContent1 = <div className='d-lg-flex mt-4 mt-lg-5 text-center text-lg-left'>
        <img src={jobSeekerIcon1} height='72' />
        <div className='ml-lg-4'>
            <h1 className='font-weight-normal font-size-smaller'>
                <Text id='home::text::job-seeker::content1:title' />
            </h1>
            <h1 className='font-weight-light font-size-smaller'>
                <Text id='home::text::job-seeker::content1:body' />
            </h1>
        </div>
    </div>

    const homeJobSeekerContent2 = <div className='d-lg-flex mt-5 text-center text-lg-left'>
        <img src={jobSeekerIcon2} height='72' />
        <div className='ml-lg-4'>
            <h1 className='font-weight-normal font-size-smaller'>
                <Text id='home::text::job-seeker::content2:title' />
            </h1>
            <h1 className='font-weight-light font-size-smaller'>
                <Text id='home::text::job-seeker::content2:body' />
            </h1>
        </div>
    </div>

    const homeJobSeekerContent3 = <div className='d-lg-flex mt-5 text-center text-lg-left'>
        <img src={jobSeekerIcon3} height='72' />
        <div className='ml-lg-4'>
            <h1 className='font-weight-normal font-size-smaller'>
                <Text id='home::text::job-seeker::content3:title' />
            </h1>
            <h1 className='font-weight-light font-size-smaller'>
                <Text id='home::text::job-seeker::content3:body' />
            </h1>
        </div>
    </div>

    const homeRecruiterContent1 = <div className='d-lg-flex mt-4 mt-lg-5 text-center text-lg-left'>
        <img src={recruiterIcon1} height='72' />
        <div className='ml-lg-4'>
            <h1 className='font-weight-normal font-size-smaller'>
                <Text id='home::text::recruiter::content1:title' />
            </h1>
            <h1 className='font-weight-light font-size-smaller'>
                <Text id='home::text::recruiter::content1:body' />
            </h1>
        </div>
    </div>

    const homeRecruiterContent2 = <div className='d-lg-flex mt-5 text-center text-lg-left'>
        <img src={recruiterIcon2} height='72' />
        <div className='ml-lg-4'>
            <h1 className='font-weight-normal font-size-smaller'>
                <Text id='home::text::recruiter::content2:title' />
            </h1>
            <h1 className='font-weight-light font-size-smaller'>
                <Text id='home::text::recruiter::content2:body' />
            </h1>
        </div>
    </div>

    const homeRecruiterContent3 = <div className='d-lg-flex mt-5 text-center text-lg-left'>
        <img src={recruiterIcon3} height='72' />
        <div className='ml-lg-4'>
            <h1 className='font-weight-normal font-size-smaller'>
                <Text id='home::text::recruiter::content3:title' />
            </h1>
            <h1 className='font-weight-light font-size-smaller'>
                <Text id='home::text::recruiter::content3:body' />
            </h1>
        </div>
    </div>

    const homeSeparator = <div className='col-12 my-3'>
        <hr></hr>
    </div>

    const desktopView = <div className={desktopRowClass}>
        {homeTitleText}
        <div className='col-12 my-5 p-0 d-flex'>
            <div className='col-12 p-0'>
                <img src={background} className='w-100' />
            </div>
            <div className='col-12 d-flex p-0 h-100 position-absolute'>
                <div className='col-3 offset-3 p-0'>
                    <p className='text-white font-weight-normal' style={selectionText}>
                        <Text id='home::text::job-seeker::title' />
                    </p>
                    <Button size='lg' solid={false} onClick={() => uploadResumeModal.current.showModal()}>
                        <Text id='home::button::upload-resume' />
                    </Button>
                    <div className='pl-5 ml-4 mt-1'>
                        <NavLink to={Pages.FIND_JOBS}>
                            <small className='text-white'>
                                <Text id='home::link::learn-more' /> <ArrowRight />
                            </small>
                        </NavLink>
                    </div>
                </div>
                <div className='col-4 p-0' style={marginLeft5vw}>
                    <p className='font-weight-normal' style={selectionText}>
                        <Text id='home::text::recruiter::title' />
                    </p>
                    <Button size='lg' onClick={() => requestDemoModal.current.showModal()}>
                        <Text id='home::button::book-demo' />
                    </Button>
                    <div className='pl-5 ml-2 mt-1'>
                        <NavLink to={Pages.FIND_TALENT}>
                            <small className='text-blue'>
                                <Text id='home::link::learn-more' /> <ArrowRight />
                            </small>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
        {homeMiddleText}
        <div className='col-12 my-5 p-0 d-flex'>
            <div className='col-4 p-0'>
                <img src={jobSeeker} className='w-100' />
            </div>
            <div className='col-6 offset-1 p-0'>
                <h1 className='font-weight-lighter font-size-small'>
                    <Text id='home::text::job-seeker::seperator::title' values={{
                        bold: msg => <span className='font-weight-bold'>{msg}</span>
                    }} />
                </h1>
                <h1 className='font-weight-lighter mb-5 font-size-small'>
                    <Text id='home::text::job-seeker::seperator::subtitle' />
                </h1>
                {homeJobSeekerContent1}
                {homeJobSeekerContent2}
                {homeJobSeekerContent3}
                <div className='d-flex mt-5 align-items-center'>
                    <Button size='lg' onClick={() => uploadResumeModal.current.showModal()}>
                        <Text id='home::button::upload-resume' />
                    </Button>
                    <NavLink to={Pages.FIND_JOBS} className='ml-4 d-none d-flex'>
                        <small className='text-blue'>
                            <Text id='home::link::learn-more' /> <ArrowRight />
                        </small>
                    </NavLink>
                </div>
            </div>
        </div>
        <div className='col-12 my-5 p-0 d-flex'>
            <div className='col-6 offset-1 p-0'>
                <h1 className='font-weight-lighter font-size-small'>
                    <Text id='home::text::recruiter::seperator::title' values={{
                        bold: msg => <span className='font-weight-bold'>{msg}</span>
                    }} />
                </h1>
                <h1 className='font-weight-lighter mb-5 font-size-small'>
                    <Text id='home::text::recruiter::seperator::subtitle' />
                </h1>
                {homeRecruiterContent1}
                {homeRecruiterContent2}
                {homeRecruiterContent3}
                <div className='d-flex mt-5 align-items-center'>
                    <Button size='lg' onClick={() => requestDemoModal.current.showModal()}>
                        <Text id='home::button::book-demo' />
                    </Button>
                    <NavLink to={Pages.FIND_TALENT} className='ml-4 d-none d-flex'>
                        <small className='text-blue'>
                            <Text id='home::link::learn-more' /> <ArrowRight />
                        </small>
                    </NavLink>
                </div>
            </div>
            <div className='col-4 offset-1 p-0'>
                <img src={recruiter} className='w-100' />
            </div>
        </div>
    </div>

    const mobileView = <div className={mobileRowClass}>
        {homeTitleText}
        <div className='col-12 my-3 p-0 d-flex'>
            <div className='col-4 p-0'>
                <img src={jobSeekerSmall} className='w-100' />
            </div>
            <div className='col-8'>
                <h1 className='font-size-smaller mb-4'>Find your dream job</h1>
                <Button size='sm' onClick={() => uploadResumeModal.current.showModal()}>
                    <Text id='home::button::upload-resume' />
                </Button>
                <NavLink to={Pages.FIND_JOBS} className='d-block mt-2'>
                    <small className='font-weight-lighter font-size-tiny text-blue' style={underlineLightGrey}>
                        <Text id='home::link::learn-more-long' />
                    </small>
                </NavLink>
            </div>
        </div>
        {homeSeparator}
        <div className='col-12 my-3 p-0 d-flex'>
            <div className='col-4 p-0'>
                <img src={recruiterSmall} className='w-100' />
            </div>
            <div className='col-8'>
                <h1 className='font-size-smaller mb-4'>Find the best talent</h1>
                <Button size='sm' onClick={() => requestDemoModal.current.showModal()}>
                    <Text id='home::button::book-demo' />
                </Button>
                <NavLink to={Pages.FIND_TALENT} className='d-block mt-2'>
                    <small className='font-weight-lighter font-size-tiny text-blue' style={underlineLightGrey}>
                        <Text id='home::link::learn-more-long' />
                    </small>
                </NavLink>
            </div>
        </div>
        {homeSeparator}
        {homeMiddleText}
        <div className='col-10 d-block p-0 my-3'>
            <img src={jobSeekerBanner} className='w-100' />
            <div className='col-9 offset-3 position-absolute text-white' style={mobileSectionText}>
                <h1 className='font-weight-lighter font-size-smaller'>
                    <Text id='home::text::job-seeker::seperator::title' values={{
                        bold: msg => <span className='font-weight-bold'>{msg}</span>
                    }} />
                </h1>
                <h1 className='font-weight-lighter font-size-smaller'>
                    <Text id='home::text::job-seeker::seperator::subtitle' />
                </h1>
            </div>
        </div>
        <div className='col-12 mb-3 p-0 d-flex'>
            <div className='col-10 offset-1 offset-1 p-0'>
                {homeJobSeekerContent1}
                {homeJobSeekerContent2}
                {homeJobSeekerContent3}
                <div className='mt-5 text-center align-items-center'>
                    <Button size='lg' onClick={() => uploadResumeModal.current.showModal()}>
                        <Text id='home::button::upload-resume' />
                    </Button>
                    <NavLink to={Pages.FIND_JOBS} className='d-block mt-2'>
                        <small className='font-weight-lighter font-size-tiny text-blue' style={underlineLightGrey}>
                            <Text id='home::link::learn-more-long' />
                        </small>
                    </NavLink>
                </div>
            </div>
        </div>
        <div className='col-10 p-0 my-3'>
            <img src={recruiterBanner} className='w-100' />
            <div className='col-9 offset-3 position-absolute text-white' style={mobileSectionText}>
                <h1 className='font-weight-lighter font-size-smaller'>
                    <Text id='home::text::recruiter::seperator::title' values={{
                        bold: msg => <span className='font-weight-bold'>{msg}</span>
                    }} />
                </h1>
                <h1 className='font-weight-lighter mb-5 font-size-smaller'>
                    <Text id='home::text::recruiter::seperator::subtitle' />
                </h1>
            </div>
        </div>
        <div className='col-12 mb-3 p-0 d-flex'>
            <div className='col-10 offset-1 offset-1 p-0'>
                {homeRecruiterContent1}
                {homeRecruiterContent2}
                {homeRecruiterContent3}
                <div className='mt-5 text-center align-items-center'>
                    <Button size='lg' onClick={() => requestDemoModal.current.showModal()}>
                        <Text id='home::button::book-demo' />
                    </Button>
                    <NavLink to={Pages.FIND_TALENT} className='d-block mt-2'>
                        <small className='font-weight-lighter font-size-tiny text-blue' style={underlineLightGrey}>
                            <Text id='home::link::learn-more-long' />
                        </small>
                    </NavLink>
                </div>
            </div>
        </div>
    </div>

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'home::text::title-name' })}`}
                </title>
            </Helmet>
            {desktopView}
            {mobileView}
            <UploadResume ref={uploadResumeModal} />
            <RequestDemo ref={requestDemoModal} />
        </React.Fragment>
    );
}

export default Home;
