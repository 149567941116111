import React, { useState } from 'react';
import SpringCarousel from 'react-spring-3d-carousel';
import { config } from 'react-spring';
import PropTypes from 'prop-types';

Carousel.propTypes = {
  contents: PropTypes.array,
}

const getTouches = (evt) => {
  return (
    evt.touches || evt.originalEvent.touches // browser API
  );
};

function Carousel({ contents = [] }) {
  const [carouselState, setCarouselState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: false,
    enableSwipe: true,
    config: config.gentle,
    xDown: null,
    yDown: null
  });

  const slides = contents.map((content, index) => {
    const slide = { key: index, content: content }
    return { ...slide, onClick: () => setCarouselState((prevState) => ({ ...prevState, goToSlide: index })) };
  });

  const handleTouchStart = (evt) => {
    if (!carouselState.enableSwipe) {
      return;
    }

    const firstTouch = getTouches(evt)[0];
    setCarouselState({
      ...carouselState,
      xDown: firstTouch.clientX,
      yDown: firstTouch.clientY
    });
  };

  const handleTouchMove = (evt) => {
    if (!carouselState.enableSwipe || (!carouselState.xDown && !carouselState.yDown)) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = carouselState.xDown - xUp;
    let yDiff = carouselState.yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        /* left swipe */
        setCarouselState({
          ...carouselState,
          goToSlide: carouselState.goToSlide + 1,
          xDown: null,
          yDown: null
        });
      } else {
        /* right swipe */
        setCarouselState({
          ...carouselState,
          goToSlide: carouselState.goToSlide - 1,
          xDown: null,
          yDown: null
        });
      }
    }
  };

  return (
    <div
      style={{ width: '70%', height: '500px', margin: '0 auto' }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <SpringCarousel
        slides={slides}
        goToSlide={carouselState.goToSlide}
        offsetRadius={carouselState.offsetRadius}
        showNavigation={carouselState.showNavigation}
        animationConfig={carouselState.config}
      />
    </div>
  );
}

export default Carousel;