import React, { useRef } from 'react';
import { ArrowRight } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import Text from '../components/text';
import Button from '../components/button';
import Titleimg from '../assets/images/find-talent-title-img.png';
import Howitwork from '../assets/images/find-talent-how-it-works.png';
import Reason1 from '../assets/images/find-talent-reason1.png';
import Reason2 from '../assets/images/find-talent-reason2.png';
import Reason3 from '../assets/images/find-talent-reason3.png';
import Reason4 from '../assets/images/find-talent-reason4.png';
import Reason5 from '../assets/images/find-talent-reason5.png';
import Reason6 from '../assets/images/find-talent-reason6.png';
import Book from '../assets/images/ebook-img.png';
import RequestDemo from '../components/modal/request-demo.js';

function FindTalent() {
	// Modal
	const requestDemoModal = useRef();

	const reasons = [
		{ id: 1, img: Reason1 },
		{ id: 2, img: Reason2 },
		{ id: 3, img: Reason3 },
		{ id: 4, img: Reason4 },
		{ id: 5, img: Reason5 },
		{ id: 6, img: Reason6 },
	];
	const reasonImg = {
		height: '63px',
	};
	const reasonBlock = {
		textAlign: 'center',
	};
	const grexBox = {
		padding: '32px 48px',
		backgroundColor: '#f5f5f6',
	};
	return (
		<>
			<Helmet>
				<title>
					{`${useIntl().formatMessage({ id: 'find-talent::text::title-name' })}`}
				</title>
			</Helmet>
			<div className='my-5 justify-content-center'>
				<div className='col-lg-10 m-auto'>
					<div className='row align-items-top'>
						<div className='col-lg-6 m-auto'>
							<h1 className='text-blue font-weight-bold font-size-normal'>
								<Text id='find-talent::text::title'></Text>
							</h1>
							<p className='font-weight-light font-size-smaller'>
								<Text id='find-talent::text::desc'></Text>
							</p>
							<div className='d-lg-flex align-items-center text-center text-lg-left'>
								<Button size='lg' onClick={() => requestDemoModal.current.showModal()}>
									<Text id='home::button::book-demo' />
								</Button>
								<div className='pl-lg-5 mt-1'>
									<a href='#reasons'>
										<small className='text-blue'>
											<Text id='home::link::learn-more' />{' '}
											<ArrowRight />
										</small>
									</a>
								</div>
							</div>
						</div>
						<div className='col-lg-6'>
							<img className='w-100' src={Titleimg} alt='' />
						</div>
					</div>
					<div className='row justify-content-center my-5'>
						<div className='col-10'>
							<p className='font-weight-lighter font-size-small text-center'>
								<Text id='find-talent::text::desc2' values={{
									bold: msg => <span className='font-weight-bold'>{msg}</span>
								}}></Text>
							</p>
						</div>
					</div>
					<div className='row align-items-lg-center my-4'>
						<div className='col-lg-5 mb-4 mb-lg-0'>
							<img src={Howitwork} alt='' className='w-100' />
						</div>
						<div className='col-lg-6 offset-lg-1'>
							<h2 className='font-size-small'>
								<Text id='find-talent::text::how-it-works' />
							</h2>
							<p className='font-weight-light font-size-smaller'>
								<Text id='find-talent::text::work1' />
								<br />
								<Text id='find-talent::text::work2' />
								<br />
								<Text id='find-talent::text::work3' />
							</p>
						</div>
					</div>
					<div className='row my-5 pt-4' id='reasons'>
						<div className='col-12'>
							<h2 className='text-blue font-weight-bold font-size-normal text-center'>
								<Text id='find-talent::text::reason-title' />
							</h2>
						</div>
						<div className='col-12'>
							<div className='row my-4'>
								{reasons.map((reason) => (
									<div
										className='col-lg-4 col-md-6 my-4 px-5'
										style={reasonBlock}
										key={reason.id}
									>
										<img
											src={reason.img}
											style={reasonImg}
											alt=''
										/>
										<p className='font-size-smaller my-3'>
											<Text
												id={`find-talent::text::reason${reason.id}-desc1`}
											/>
										</p>
										<p className='font-size-smaller font-weight-lighter my-3'>
											<Text
												id={`find-talent::text::reason${reason.id}-desc2`}
											/>
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
					<div style={grexBox}>
						<div className='row'>
							<div className='col-lg-9 m-auto order-2 order-lg-1'>
								<h2 className='text-blue font-size-small'>
									<Text id='find-talent::text::learn-more' />
								</h2>
								<p className='font-weight-lighter font-size-smaller'>
									<Text id='find-talent::text::learn-more-desc' />
								</p>
								<Button
									size='lg'
									onClick={() =>
										(window.location.href = '/ebook')
									}
								>
									<Text id='find-talent::text::download-ebook' />
								</Button>
							</div>
							<div className='col-lg-3 order-1 order-lg-2 mb-5'>
								<img src={Book} className='w-100' alt='' />
							</div>
						</div>
					</div>
				</div>
				<RequestDemo ref={requestDemoModal} />
			</div>
		</>
	);
}

export default FindTalent;
