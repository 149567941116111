import React, { useState, forwardRef, useImperativeHandle, useContext } from 'react';
import { userContext } from '../../utils/user';
import { APIs } from '../../utils/constants';
import { Form, Input } from 'antd';
import ModalWithAlert from './modal';
import Button from '../button';
import Text from '../text';

const EditPassword = forwardRef((_, ref) => {
    const user = useContext(userContext);
    const [form] = Form.useForm();
    const initialFields = [
        {
            name: ['current-password'],
            value: ''
        },
        {
            name: ['new-password'],
            value: ''
        },
        {
            name: ['confirm-password'],
            value: ''
        }
    ];

    // States
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalAlerts, setModalAlerts] = useState([]);
    const [fields, setFields] = useState(initialFields);

    // Actions
    useImperativeHandle(ref, () => ({
        showModal() {
            setIsModalVisible(true);
        }
    }))

    const handleModalClose = () => {
        setModalAlerts([]);
        setIsModalVisible(false);
        setFields(initialFields);
    }

    const handleErrorAlerts = () => {
        setModalAlerts([
            ...modalAlerts,
            {
                type: 'error',
                content: <Text id='modal::edit-password::message::error' />
            }
        ]);
    };

    const handleModalSubmit = () => {
        form
            .validateFields()
            .then(async values => {
                try {
                    const response = await fetch(APIs.EDIT_PASSWORD, {
                        method: 'POST', body: JSON.stringify({
                            user: user.user.id,
                            token: user.user.deviceIdentity,
                            current_password: values['current-password'].trim(),
                            new_password: values['new-password'].trim(),
                            confirm_password: values['confirm-password'].trim(),
                        })
                    });
                    if (response.ok) {
                        handleModalClose();
                    } else {
                        handleErrorAlerts();
                    }
                } catch (error) {
                    handleErrorAlerts();
                }
            })
    }

    return (
        <ModalWithAlert
            title={<Text id='modal::edit-password::title::edit-password' />}
            visible={isModalVisible}
            onCancel={handleModalClose}
            alerts={modalAlerts}
            footer={[
                <Button key='submit' onClick={handleModalSubmit}>
                    <Text id='modal::edit-password::button::save' />
                </Button>
            ]}>
            <div className='text-gray mt-3'>
                <Form
                    form={form}
                    fields={fields}
                    onFieldsChange={(_, newFields) => {
                        setFields(newFields);
                    }}
                    layout='vertical'>
                    <Form.Item
                        name='current-password'
                        label={<Text id='modal::edit-password::text::current-password' />}
                        rules={[
                            { required: true, message: <Text id='modal::edit-password::error::current-password-required' /> }
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name='new-password'
                        label={<Text id='modal::edit-password::text::new-password' />}
                        rules={[
                            { required: true, message: <Text id='modal::edit-password::error::new-password-required' /> }
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name='confirm-password'
                        label={<Text id='modal::edit-password::text::confirm-password' />}
                        rules={[
                            { required: true, message: <Text id='modal::edit-password::error::confirm-password-required' /> },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('new-password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(<Text id='modal::edit-password::error::password-not-match' />);
                                },
                            })
                        ]}>
                        <Input.Password />
                    </Form.Item>
                </Form>
            </div>
        </ModalWithAlert>
    );
})

export default EditPassword;
