import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { DatePicker, Form, Input } from 'antd';
import ModalWithAlert from './modal';
import Button from '../button';
import Text from '../text';
import PropTypes from 'prop-types';
import moment from 'moment';

const EditProject = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const initialFields = [
        { name: ['project_name'], value: null },
        { name: ['affiliated_organization'], value: null },
        { name: ['start_date'], value: null },
        { name: ['end_date'], value: null },
        { name: ['details'], value: null },
    ];

    // States
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalAlerts, setModalAlerts] = useState([]);
    const [modalTitle, setModalTitle] = useState(null);
    const [fields, setFields] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [projectIdCounter, setProjectIdCounter] = useState(0);

    // Actions
    useImperativeHandle(ref, () => ({
        showModal(data) {
            if (data) {
                setModalTitle('Edit Project');
                setIsEdit(true);
                setFields([
                    { name: ['project_name'], value: data['project_name'] },
                    { name: ['affiliated_organization'], value: data['affiliated_organization'] },
                    { name: ['start_date'], value: data['start_date'] ? moment(data['start_date']) : null }, // sets date to null if field is empty
                    { name: ['end_date'], value: moment(data['end_date']) },
                    { name: ['details'], value: data['details'] },
                ]);
            } else {
                setModalTitle('Add Project');
                setIsEdit(false);
                setFields(initialFields);
            }
            setIsModalVisible(true);
        }
    }))

    const handleModalClose = () => {
        setIsModalVisible(false);
        setFields(initialFields);
        setModalAlerts([]);
    }

    const handleModalSubmit = () => {
        form.validateFields().then(values => {
            const projectData = {
                id: projectIdCounter, // assigns a new counter
                project_name: values.project_name,
                affiliated_organization: values.affiliated_organization,
                start_date: values.start_date ? values.start_date.format('YYYY-MM') : null,
                end_date: values.end_date ? values.end_date.format('YYYY-MM') : null,
                details: values.details
            };
            if (isEdit) {
                props.editProject(projectData);
                setProjectIdCounter(projectIdCounter + 1); // since each time you edit, that project's id changes to idCounter
            } else {
                props.addProject(projectData);
                setProjectIdCounter(projectIdCounter + 1);
            }

            handleModalClose();
        });
    }

    return (
        <ModalWithAlert
            title={modalTitle}
            visible={isModalVisible}
            onCancel={handleModalClose}
            alerts={modalAlerts}
            footer={[]}
        >
            <div className='flex-column text-gray mt-3'>
                <Form
                    form={form}
                    fields={fields}
                    onFieldsChange={(_, newFields) => {
                        setFields(newFields);
                    }}
                    layout='vertical'>
                    <Form.Item
                        name='project_name'
                        label='Project Name'
                        rules={[{ required: true, message: 'Project name required' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='affiliated_organization' label='Affiliated Organization'>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='start_date'
                        label='Start Date'>
                        <DatePicker className='w-100' format="MM/YYYY" picker="month" />
                    </Form.Item>
                    <Form.Item
                        name='end_date'
                        label='End Date'
                        rules={[{ required: true, message: 'End date required' }]}>
                        <DatePicker className='w-100' format="MM/YYYY" picker="month" />
                    </Form.Item>
                    <Form.Item name='details' label='Details'>
                        <Input.TextArea rows={5} />
                    </Form.Item>
                </Form>
                <div className='d-flex justify-content-center'>
                    <Button key='submit' onClick={handleModalSubmit} className='mt-4 col-4'>
                        <Text id='modal::edit-interview::button::save' />
                    </Button>
                </div>
            </div>
        </ModalWithAlert>
    );
})

export default EditProject;
EditProject.propTypes = {
    addProject: PropTypes.func,
    editProject: PropTypes.func
}