import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Button from '../button.js';
import Text from '../text.js';
import { APIs, EmailPattern, PhoneNumberPattern } from '../../utils/constants.js';
import ModalWithAlert from './modal.js';
import { InputNumber, Form, Input } from 'antd';

const DownloadEbook = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const errorMap = {
        'general': {
            type: 'error',
            content: <Text id='message::error::general' />
        },
        'E00012': {
            type: 'error',
            content: <Text id='modal::download-ebook::message::error::not-send-download-ebook-email' />
        }
    };

    // States
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalAlerts, setModalAlerts] = useState([]);
    const [fields, setFields] = useState([
        {
            name: ['fullname'],
            value: ''
        },
        {
            name: ['email'],
            value: ''
        },
        {
            name: ['phone-number'],
            value: ''
        },
        {
            name: ['job-title'],
            value: ''
        },
        {
            name: ['company'],
            value: ''
        }
    ]);

    // Actions
    useImperativeHandle(ref, () => ({
        showModal() {
            setIsModalVisible(true);
        }
    }))

    const handleModalClose = () => {
        setIsModalVisible(false);
    }

    const handleErrorAlerts = errorCode => {
        setModalAlerts([
            ...modalAlerts,
            errorMap[errorCode] ? errorMap[errorCode] : errorMap['general']
        ]);
    };

    const handleModalSubmit = () => {
        form
            .validateFields()
            .then(async values => {
                try {
                    const response = await fetch(APIs.DOWNLOAD_EBOOK, {
                        method: 'POST', body: JSON.stringify({
                            fullname: values.fullname.trim(),
                            email: values.email.trim(),
                            phone: values.phone,
                            position: values.position.trim(),
                            company: values.company.trim()
                        })
                    });
                    const r = await response.json();
                    if (response.ok) {
                        setModalAlerts([
                            ...modalAlerts,
                            {
                                type: 'success',
                                content: <Text id='modal::download-ebook::message::success::title' />,
                                description: <Text id='modal::download-ebook::message::success::description' />
                            }
                        ]);
                    } else {
                        handleErrorAlerts(r.error);
                    }
                } catch (error) {
                    // handleError();
                }
            })
    }

    return (
        <ModalWithAlert
            title={<Text id='modal::download-ebook::text::download-ebook' />}
            visible={isModalVisible}
            onCancel={handleModalClose}
            alerts={modalAlerts}
            footer={[
                <Button key='submit' onClick={handleModalSubmit}>
                    <Text id='modal::download-ebook::button::download' />
                </Button>
            ]}>
            <div className='text-gray mt-3'>
                <Form
                    form={form}
                    fields={fields}
                    onFieldsChange={(_, newFields) => {
                        setFields(newFields);
                    }}
                    layout='vertical'
                    name='download-ebook-form'>
                    <Form.Item
                        name='fullname'
                        label={<Text id='modal::download-ebook::text::fullname' />}
                        rules={[
                            { required: true, message: <Text id='modal::download-ebook::error::fullname-required' /> }
                        ]}>
                        <Input className='w-50' />
                    </Form.Item>
                    <Form.Item
                        name='email'
                        label={<Text id='modal::download-ebook::text::email' />}
                        rules={[
                            { required: true, message: <Text id='modal::download-ebook::error::email-required' /> },
                            { pattern: EmailPattern, message: <Text id='modal::download-ebook::error::email-invalid' /> }
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='phone'
                        label={<Text id='modal::download-ebook::text::phone-number' />}
                        rules={[
                            { required: true, message: <Text id='modal::download-ebook::error::phone-number-required' /> },
                            { pattern: PhoneNumberPattern, message: <Text id='modal::download-ebook::error::phone-number-invalid' /> }
                        ]}>
                        <InputNumber className='w-100 border-0' />
                    </Form.Item>
                    <Form.Item
                        name='position'
                        label={<Text id='modal::download-ebook::text::job-title' />}
                        rules={[
                            { required: true, message: <Text id='modal::download-ebook::error::job-title-required' /> }
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='company'
                        label={<Text id='modal::download-ebook::text::company' />}
                        rules={[
                            { required: true, message: <Text id='modal::download-ebook::error::company-required' /> }
                        ]}>
                        <Input />
                    </Form.Item>
                </Form>
            </div>
        </ModalWithAlert>
    );
})

export default DownloadEbook;
