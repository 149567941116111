import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Pages } from '../../utils/constants';
import { Card, Col, Collapse, Row, Typography } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Button from '../../components/button';
import Carousel from '../../components/carousel';
import Text from '../../components/text';
import HeaderImg from '../../assets/images/home2-header.png';
import AIDrivenImg from '../../assets/images/ai-driven.png';
import DreamJobImg from '../../assets/images/dream-job.png';
import AtsImg from '../../assets/images/ats.png';
import JobWinningImg from '../../assets/images/job-winning.png';
import AffordableImg from '../../assets/images/affordable.png';
import FullCycleImg from '../../assets/images/full-cycle.png';
import FaqImg from '../../assets/images/faq.png';
import Quotation1Img from '../../assets/images/quotation1.png';
import Quotation2Img from '../../assets/images/quotation2.png';
import AvatarImg from '../../assets/images/avatar.png';
import SampleResumeImg from '../../assets/images/sample-resume.png';

function Home() {
    const featureContents = [
        {
            img: AIDrivenImg,
            prefix: 'resume-gpt::home::text::feature::ai-driven::',
        },
        {
            img: DreamJobImg,
            prefix: 'resume-gpt::home::text::feature::dream-job::',
        },
        {
            img: AtsImg,
            prefix: 'resume-gpt::home::text::feature::ats::',
        },
        {
            img: JobWinningImg,
            prefix: 'resume-gpt::home::text::feature::job-winning::',
        },
        {
            img: AffordableImg,
            prefix: 'resume-gpt::home::text::feature::affordable::',
        },
        {
            img: FullCycleImg,
            prefix: 'resume-gpt::home::text::feature::full-cycle::',
        },
    ].map((content, index) => {
        return (
            <Col xs={24} md={8} className='p-4' key={index}>
                <div className='text-center mb-4'>
                    <img src={content.img} width={100} />
                </div>
                <Typography.Title level={5}>
                    <Text id={content.prefix + 'title'} />
                </Typography.Title>
                <Typography.Text>
                    <Text id={content.prefix + 'description'} />
                </Typography.Text>
            </Col>
        );
    })

    const getFaqs = () => {
        const prefix = 'resume-gpt::home::text::faqs::';
        const keys = [
            'what-is-jobster',
            'non-english-resume',
            'your-resume-different',
            'generative-ai',
            'different-from-others',
            'single-page',
        ];

        return keys.map((key, index) => {
            return {
                key: index,
                label: (
                    <Typography.Title level={5} className='m-0'>
                        <Text id={prefix + key + '::question'} />
                    </Typography.Title>
                ),
                children: (
                    <Typography.Paragraph>
                        <Text id={prefix + key + '::answer'} />
                    </Typography.Paragraph>
                ),
            };
        });
    };

    const testimonials = [
        {
            person: 'Cathy',
            position: 'Actuarial Analyst',
            company: 'Major Insurance Company',
            review: 'I got lots of interview opportunities after applying for the openings Jobster recommended. I received my dream offer after that.'
        },
        {
            person: 'Cathy',
            position: 'Actuarial Analyst',
            company: 'Major Insurance Company',
            review: 'I got lots of interview opportunities after applying for the openings Jobster recommended. I received my dream offer after that.'
        },
        {
            person: 'Cathy',
            position: 'Actuarial Analyst',
            company: 'Major Insurance Company',
            review: 'I got lots of interview opportunities after applying for the openings Jobster recommended. I received my dream offer after that.'
        }
    ].map((testimonial, index) => {
        return <Col xs={24} md={8} className='text-center p-4' key={index}>
            <Card
                className='shadow-lg'
                cover={
                    <div className='p-4'>
                        <div className='text-left'>
                            <img width={25} src={Quotation1Img} />
                        </div>
                        <div className='py-2 px-4'>
                            <Typography.Text>
                                {testimonial.review}
                            </Typography.Text>
                        </div>
                        <div className='text-right'>
                            <img width={25} src={Quotation2Img} />
                        </div>
                    </div>
                }
                bodyStyle={{ backgroundColor: '#2E3C4D' }}
            >
                <Row>
                    <Col span={6}>
                        <div className='bg-gray text-center rounded-circle'>
                            <img src={AvatarImg} className='w-100' />
                        </div>
                    </Col>
                    <Col span={16} offset={2} className='text-left'>
                        <Typography.Title level={5} className='text-white'>
                            {testimonial.name}
                        </Typography.Title>
                        <div>
                            <Typography.Text className='text-white'>
                                {testimonial.position}
                            </Typography.Text>
                        </div>
                        <div>
                            <Typography.Text className='text-white'>
                                at {testimonial.company}
                            </Typography.Text>
                        </div>
                    </Col>
                </Row>
            </Card>
        </Col>
    })

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'resume-gpt::home::text::title-name' })}`}
                </title>
            </Helmet>
            <Row className='px-5'>
                <Col xs={{ order: 2, span: 24 }} md={{ order: 1, span: 12 }} className='my-auto p-4'>
                    <Typography.Title level={3}>
                        <Text id='resume-gpt::home::text::title::sub-title' />
                    </Typography.Title>
                    <Typography.Title className='jobster-font-weight-800'>
                        <Text id='resume-gpt::home::text::title::title' values={{
                            newline: msg => <div>{msg}</div>,
                            bold: msg => <span className='text-gold'>{msg}</span>
                        }}></Text>
                    </Typography.Title>
                    <Typography.Paragraph className='font-weight-light'>
                        <Text id='resume-gpt::home::text::intro::content1'></Text>
                    </Typography.Paragraph>
                    <Typography.Paragraph className='font-weight-bold'>
                        <Text id='resume-gpt::home::text::intro::content2'></Text>
                    </Typography.Paragraph>
                    <NavLink to={Pages.RESUME_GPT_START}>
                        <Button className='mt-3'>
                            <Text id='resume-gpt::home::button::get-started'></Text>
                        </Button>
                    </NavLink>
                </Col>
                <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 12 }} className='text-center p-4'>
                    <img src={HeaderImg} className='w-100' />
                </Col>
            </Row>
            <Row className='bg-gray p-5'>
                <Col span={24} className='text-center'>
                    <Typography.Title level={2} className='jobster-font-weight-800'>
                        <Text id='resume-gpt::home::text::feature::title' values={{
                            bold: msg => <span className='text-gold'>{msg}</span>
                        }} />
                    </Typography.Title>
                </Col>
                {featureContents}
            </Row>
            <Row className='m-auto w-100 p-5'>
                <Col xs={24} md={{ span: 8, offset: 8 }} className='mb-4'>
                    <Typography.Title level={2} className='jobster-font-weight-800 text-center'>
                        <Text id='resume-gpt::home::text::resume-galary::title' values={{
                            bold: msg => <span className='text-gold'>{msg}</span>
                        }} />
                    </Typography.Title>
                    <Typography.Text>
                        <Text id='resume-gpt::home::text::resume-galary::sub-title' />
                    </Typography.Text>
                </Col>
                <Col span={24}>
                    <Carousel contents={[
                        <img key={1} src={SampleResumeImg} />,
                        <img key={2} src={SampleResumeImg} />,
                        <img key={3} src={SampleResumeImg} />,
                        <img key={4} src={SampleResumeImg} />,
                        <img key={5} src={SampleResumeImg} />,
                        <img key={6} src={SampleResumeImg} />,
                        <img key={7} src={SampleResumeImg} />]} />
                </Col>
            </Row>
            <Row className='bg-gray px-5'>
                <Col xs={24} md={12} className='text-center p-4'>
                    <img src={FaqImg} className='w-100' />
                </Col>
                <Col xs={24} md={12} className='my-auto p-4'>
                    <Typography.Title level={2} className='jobster-font-weight-800'>
                        <Text id='resume-gpt::home::text::faq::title' values={{
                            bold: msg => <span className='text-gold'>{msg}</span>
                        }} />
                    </Typography.Title>
                    <Collapse
                        accordion
                        ghost
                        items={getFaqs()}
                        expandIcon={({ isActive }) =>
                            isActive ? (
                                <MinusCircleOutlined style={{ fontSize: '17px' }} />
                            ) : (
                                <PlusCircleOutlined style={{ fontSize: '17px' }} />
                            )
                        }
                    />
                </Col>
            </Row>
            <Row className='w-100 p-5'>
                <Col span={24} className='text-center'>
                    <Typography.Title level={2} className='jobster-font-weight-800'>
                        <Text id='resume-gpt::home::text::testmonial::title' values={{
                            bold: msg => <span className='text-gold'>{msg}</span>
                        }} />
                    </Typography.Title>
                </Col>
                {testimonials}
            </Row>
        </React.Fragment>
    );
}

export default Home;
