import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Upload, Progress } from 'antd';
import docIcon from '../../assets/images/doc-icon.png';
import docxIcon from '../../assets/images/docx-icon.png';
import pdfIcon from '../../assets/images/pdf-icon.png';
import txtIcon from '../../assets/images/txt-icon.png';
import cloud from '../../assets/images/upload-resume-cloud.png';
import Button from '../button.js';
import Text from '../text.js';
import { APIs, Pages } from '../../utils/constants.js';
import ModalWithAlert from './modal.js';
import TextArea from 'antd/lib/input/TextArea';
import './upload-resume.scss';
import { getUser } from '../../utils/auth';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const UploadResume = forwardRef((props, ref) => {
    const history = useHistory();
    const user = getUser();
    // States
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [buttonDisableDD, setButtonDisableDD] = useState(true);
    const [buttonDisableCP, setButtonDisableCP] = useState(true);
    const [copyPasteConent, setCopyPasteConent] = useState('');
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [modalAlerts, setModalAlerts] = useState([]);
    const [isDD, setIsDD] = useState(true);

    // Modal
    const { Dragger } = Upload;

    // CSS
    const supportFileMap = {
        'doc': <img src={docIcon} width='59' />,
        'docx': <img src={docxIcon} width='59' />,
        'pdf': <img src={pdfIcon} width='59' />,
        'txt': <img src={txtIcon} width='59' />
    }

    const uploadFileSection = {
        height: '100%',
        paddingTop: '40px',
        background: '#fafafa',
        border: '1px dashed #d9d9d9',
        borderRadius: '2px'
    }

    const height206 = {
        height: '206px'
    }

    // Actions
    const draggerProps = {
        accept: 'application/pdf,.doc,.docx,application/msword,text/plain',
        multiple: false,
        onChange(info) {
            setButtonDisableDD(false);
            setFileList([info.file]);
        },
        beforeUpload: () => {
            return false;
        },
    }

    useImperativeHandle(ref, () => ({
        showModal() {
            setIsModalVisible(true);
        }
    }))

    const handleClose = () => {
        setIsModalVisible(false);
        handleRemove();
    }

    const handleRemove = () => {
        setFileList([]);
        setButtonDisableDD(true);
        setProgressPercentage(0);
    }

    const handleSubmitError = () => {
        setModalAlerts([
            ...modalAlerts,
            {
                'type': 'error',
                'content': <Text id='message::error::general' />
            }
        ]);
    }
    const handleSubmit = async () => {
        setButtonDisableDD(true);
        setProgressPercentage(10);
        const formData = new FormData();
        if (user) {
            formData.append('user', user.id);
        }

        if (isDD) {
            fileList.forEach(file => {
                formData.append('file', file);
            });
        } else {
            formData.append('content', copyPasteConent);
        }

        setProgressPercentage(30);
        try {
            setProgressPercentage(95);
            // First, upload the resume to the initial server
            const response = await fetch(APIs.UPLOAD_RESUME, { method: 'POST', body: formData });
            if (response.ok) {
                setProgressPercentage(100);
                const r = await response.json();
                history.push(props.redirect + r.id);
            } else {
                handleSubmitError();
            }
        } catch (error) {
            handleSubmitError();
        }
    }
    const handleUserTyping = e => {
        if (e.target.value.trim().length > 0) {
            setButtonDisableCP(false);
        } else {
            setButtonDisableCP(true);
        }
        setCopyPasteConent(e.target.value.trim());
    }

    const changeMode = () => {
        setIsDD(!isDD);
    }

    const getFileIcon = () => {
        return supportFileMap[fileList[0].name.split('.').pop().toLowerCase()] ?
            supportFileMap[fileList[0].name.split('.').pop().toLowerCase()] : ''
    }

    // UI
    const modalContentDD = fileList.length ?
        <div className='text-center px-3' style={uploadFileSection}>
            {getFileIcon()}
            <h5 className='text-gray font-weight-normal'>
                {fileList[0].name}
            </h5>
            <Progress percent={progressPercentage} status="active" showInfo={false} trailColor='#e2e1e1' />
            <div className='text-primary'>
                <a onClick={handleRemove}>
                    <Text id='modal::upload-resume::button::remove-file' />
                </a>
            </div>
        </div>
        :
        <Dragger {...draggerProps}>
            <p className='ant-upload-drag-icon'>
                <img src={cloud} width='55' />
            </p>
            <h5 className='text-gray font-weight-normal'>
                Drag file here or <u className='text-primary'>upload</u>
            </h5>
        </Dragger>

    const modalContentCP = <TextArea style={height206} onChange={handleUserTyping} defaultValue={copyPasteConent} />

    const modalTitle = <Text id='modal::upload-resume::text::upload-file' />

    return (
        <ModalWithAlert
            title={modalTitle}
            visible={isModalVisible}
            onCancel={handleClose}
            className='upload-resume-modal'
            alerts={modalAlerts}
            footer={[
                <Button key='submit' disabled={(isDD && buttonDisableDD) || (!isDD && buttonDisableCP)} onClick={handleSubmit}>
                    <Text id='modal::upload-resume::button::submit' />
                </Button>
            ]}>
            <>
                {isDD && modalContentDD}
                {!isDD && modalContentCP}
                <div className='text-gray mt-1'>
                    <div className='d-inline-block'>
                        <Text id='modal::upload-resume::text::support-file' />
                    </div>
                    <div className='d-inline-block float-right'>
                        <a className='text-primary' onClick={changeMode}>
                            {isDD && <Text id='modal::upload-resume::button::copy-and-paste' />}
                            {!isDD && <Text id='modal::upload-resume::button::drag-and-drop' />}
                        </a>
                    </div>
                </div>
            </>
        </ModalWithAlert>
    );
})

UploadResume.propTypes = {
    redirect: PropTypes.string
}

UploadResume.defaultProps = {
    redirect: Pages.RESULT
};

export default UploadResume;
