import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { DatePicker, Form, Input } from 'antd';
import ModalWithAlert from './modal';
import Button from '../button';
import Text from '../text';
import PropTypes from 'prop-types';
import moment from 'moment';

const EditEducation = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const initialFields = [
        { name: ['name_of_institution'], value: null },
        { name: ['degree_type'], value: null },
        { name: ['major'], value: null },
        { name: ['start_date'], value: null },
        { name: ['end_date'], value: null },
        { name: ['gpa'], value: null },
        { name: ['details'], value: null },
    ];

    // States
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalAlerts, setModalAlerts] = useState([]);
    const [modalTitle, setModalTitle] = useState(null);
    const [fields, setFields] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [educationIdCounter, setEducationIdCounter] = useState(0);

    // Actions
    useImperativeHandle(ref, () => ({
        showModal(data) {
            if (data) {
                setModalTitle('Edit Education');
                setIsEdit(true);
                setFields([
                    { name: ['name_of_institution'], value: data['name_of_institution'] },
                    { name: ['degree_type'], value: data['degree_type'] },
                    { name: ['major'], value: data['major'] },
                    { name: ['start_date'], value: data['start_date'] ? moment(data['start_date']) : null }, // sets date to null if field is empty
                    { name: ['end_date'], value: moment(data['end_date']) },
                    { name: ['gpa'], value: data['gpa'] },
                    { name: ['details'], value: data['details'] },
                ]);
            } else {
                setModalTitle('Add Education');
                setIsEdit(false);
                setFields(initialFields);
            }
            setIsModalVisible(true);
        }
    }))

    const handleModalClose = () => {
        setIsModalVisible(false);
        setFields(initialFields);
        setModalAlerts([]);
    }

    const handleModalSubmit = () => {
        form.validateFields().then(values => {
            const educationData = {
                id: educationIdCounter, // assigns a new counter
                name_of_institution: values.name_of_institution,
                degree_type: values.degree_type,
                major: values.major,
                start_date: values.start_date ? values.start_date.format('YYYY-MM') : null,
                end_date: values.end_date ? values.end_date.format('YYYY-MM') : null,
                gpa: values.gpa,
                details: values.details
            };
            if (isEdit) {
                props.editEducation(educationData);
                setEducationIdCounter(educationIdCounter + 1); // since each time you edit, that education's id changes to idCounter
            } else {
                props.addEducation(educationData);
                setEducationIdCounter(educationIdCounter + 1);
            }

            handleModalClose();
        });
    }

    return (
        <ModalWithAlert
            title={modalTitle}
            visible={isModalVisible}
            onCancel={handleModalClose}
            alerts={modalAlerts}
            footer={[]}
        >
            <div className='flex-column text-gray mt-3'>
                <Form
                    form={form}
                    fields={fields}
                    onFieldsChange={(_, newFields) => {
                        setFields(newFields);
                    }}
                    layout='vertical'>
                    <Form.Item
                        name='name_of_institution'
                        label='Institution Name'
                        rules={[{ required: true, message: 'Institution name required' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='degree_type'
                        label='Degree'
                        rules={[{ required: true, message: 'Degree name reqired' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='major' label='Major'>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='start_date'
                        label='Start Date'>
                        <DatePicker className='w-100' format="MM/YYYY" picker="month" />
                    </Form.Item>
                    <Form.Item
                        name='end_date'
                        label='End Date'
                        rules={[{ required: true, message: 'End date required' }]}>
                        <DatePicker className='w-100' format="MM/YYYY" picker="month" />
                    </Form.Item>
                    <Form.Item name='gpa' label='GPA'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='details' label='Details'>
                        <Input.TextArea rows={5} />
                    </Form.Item>
                </Form>
                <div className='d-flex justify-content-center'>
                    <Button key='submit' onClick={handleModalSubmit} className='mt-4 col-4'>
                        <Text id='modal::edit-interview::button::save' />
                    </Button>
                </div>
            </div>
        </ModalWithAlert>
    );
})

export default EditEducation;
EditEducation.propTypes = {
    addEducation: PropTypes.func,
    editEducation: PropTypes.func
}